import request from '@/plugins/http'

export function getClientList() {
    return request.get('/api/transitunit/getClientList?size=3000');
}

export function createSalePrice(param) {
    return request.post('/api/sale/createSalePrice', param);
}

export function getClientSalePriceList(param) {
    return request.get('/api/sale/getClientSalePriceList', param);
}

export function invalidSalePriceStatus(param) {
    return request.post('/api/sale/invalidSalePriceStatus', param);
}

export function validSalePriceStatus(param) {
    return request.post('/api/sale/validSalePriceStatus', param);
}

export function updateSalePrice(param) {
    return request.post('/api/sale/updateClientSalePrice', param);
}

export function deleteClientSalePrice(param) {
    return request.post('/api/sale/deleteClientSalePrice', param);
}

export function apiCreateSaleOrder(param) {
    return request.post('/api/sale/createSaleOrder', param);
}

export function apiGetOrderList(param) {
    return request.post('/api/sale/getOrderList', param);
}

export function apiPaySaleOperate(param) {
    return request.post('/api/sale/paySaleOperate', param);
}

export function apiPayReturnSaleOperate(param) {
    return request.post('/api/sale/payReturnSaleOperate', param);
}

export function apiSetSalePayStatusEnd(param) {
    return request.post('/api/sale/setSalePayStatusEnd', param);
}

export function apiSetInvoicingStatusDone(param) {
    return request.post('/api/sale/setInvoicingStatusDone', param);
}

export function apiSetSaleReturnPayStatusEnd(param) {
    return request.post('/api/sale/setSaleReturnPayStatusEnd', param);
}

export function apiSaleMoneyReturn(param) {
    return request.post('/api/sale/saleMoneyReturn', param);
}

export function apiSaleForceDeleteOperate(param) {
    return request.post('/api/sale/saleForceDeleteOperate', param);
}


export function apiGetSaleExplodeNewId() {
    return request.post('/api/sale/getSaleExplodeNewId');
}

export function apiCreateSaleExchange(param) {
    return request.post('/api/sale/createSaleExchange', param);
}

export function forceEditSaleExchangeApi(param) {
    return request.post('/api/sale/forceEditSaleExchange', param);
}


export function apiGetSaleExchangeList(param) {
    return request.post('/api/sale/getSaleExchangeList', param);
}

export function apiGetSaleExchangeDetail(param) {
    return request.post('/api/sale/getSaleExchangeDetail', param);
}

export function apiDeleteSaleExchangeList(param) {
    return request.post('/api/sale/deleteSaleExchangeList', param);
}

export function apiValidSaleExchangeOperate(param) {
    return request.post('/api/sale/validSaleExchangeOperate', param);
}

export function apiGetSaleClientMoney(param) {
    return request.post('/api/sale/getSaleClientMoney', param);
}

export function apiGetSaleClientDetail(param) {
    return request.post('/api/sale/getSaleClientDetail', param);
}



















