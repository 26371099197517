<template>
  <div class="layout">
    <Layout>
      <Layout>
        <Sider width="200">
          <SaleManageMenu ref="saleManageMenu" @func="getMsgFormMenu" :active="title_seclectd" :menuName="menuName"></SaleManageMenu>
        </Sider>
        <Content>
          <NavTag ref="navTag" @func="getMsgFormMenu"></NavTag>
          <SaleList v-if="title_seclectd == 1"></SaleList>
          <SaleExchange v-if="title_seclectd == 2"></SaleExchange>
          <SalePrice v-if="title_seclectd == 3"></SalePrice>
          <SaleNum v-if="title_seclectd == 4"></SaleNum>
          <ClientSaleNum v-if="title_seclectd == 5"></ClientSaleNum>
          <SalePrintTemplate v-if="title_seclectd == 6"></SalePrintTemplate>
          <SaleContract v-if="title_seclectd == 7"></SaleContract>
        </Content>
      </Layout>
    </Layout>
  </div>
</template>

<script>
import SaleManageMenu from '@/components/menu/SaleManageMenu.vue';
import SaleExchange from '@/views/saleManage/SaleExchange'
import NavTag from '@/components/menu/NavTags.vue';
import SaleList from '@/views/saleManage/SaleList.vue';
import SalePrice from '@/views/saleManage/SalePrice.vue';
import SaleNum from '@/views/saleManage/SaleNum';
import ClientSaleNum from '@/views/saleManage/ClientSaleNum';
import SalePrintTemplate from '@/views/saleManage/SalePrintTemplate';
import SaleContract from "@/views/saleManage/SaleContract";


export default {
  name: "SaleManage",
  components: {
    SaleManageMenu,
    SaleList,
    SalePrice,
    SaleNum,
    ClientSaleNum,
    SalePrintTemplate,
    SaleContract,
    NavTag,
    SaleExchange
  },
  data() {
    return {
      title_seclectd: "1",
      menuName: "SaleManage"
    };
  },
  mounted() {
    this.title_seclectd = localStorage.getItem(this.menuName) ? localStorage.getItem(this.menuName) : "1";
    if (this.$route.query.selected !== undefined ) {
      this.title_seclectd = this.$route.query.selected;
      localStorage.setItem(this.menuName, this.title_seclectd);
    }
    this.setNavInfo(this.title_seclectd)
  },
  methods: {
    getMsgFormMenu(data) {
      this.title_seclectd = data;
      this.setNavInfo(data)
    },
    setNavInfo(data) {
      this.$refs.navTag.setNavList(this.$route.path, this.title_seclectd)
      this.$refs.saleManageMenu.setNavInfo(data)
    }
  },
};
</script>

<style scoped>
.ivu-layout {
  background-color: transparent;
}

.ivu-layout-sider {
  background-color: transparent;
}

.ivu-menu {
  height: 100%;
}

.laypout {
  display: flex;
}
</style>
