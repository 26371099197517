<template>
  <div id="goods_explode" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">商品置换单</span>
      <Button type="primary" class="client_create" @click="createOperate('addExplode')" v-if="aCheck.rightCheck('edit_sale_exchange')">添加置换单</Button>
    </div>
    <div id="filter-bar" class="filter-bar">
      <Card dis-hover>
        <Form ref="list_search_item" :model="list.search_item" inline>
          <FormItem  prop="main_no">
            <Input @on-blur="searchSubmit" v-model="list.search_item.main_no" placeholder="请填写置换单号" class="w-200"/>
          </FormItem>

          <FormItem>&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch('list_search_item')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>
    <div id="list" class="main-list">
      <Table :columns="list.columns" :data="list.data">
        <template slot-scope="{ row }" slot="main_no">
          <strong @click="checkOrderInfo(row)" class="pointer">{{ row.main_no }}</strong>
        </template>

        <template slot-scope="{ row }" slot="status">
          <span :style="getStatusColor(row.status)">{{ getStatusText(row.status) }}</span>
        </template>

        <template slot-scope="{ row }" slot="operate_date">
          <span>{{ row.operate_date.substring(0, 10) }}</span>
        </template>

        <template slot-scope="{ row }" slot="abstract">
          <span class="fs-12">{{ row.abstract }}</span>
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <Button v-show="row.status==0" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="checkOrderInfo(row)" v-if="aCheck.rightCheck('valid_sale_exchange')">
            <Icon type="ios-checkmark-circle"/>
            提交
          </Button>
          <Button v-show="row.status==1" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="checkOrderInfo(row)" v-if="aCheck.rightCheck('view_sale_exchange')">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>

          <Dropdown v-show="[0].includes(row.status)" v-if="aCheck.rightCheck('edit_sale_exchange')">
            <Button size="small" type="text">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem  >
                <span @click="editExplodeOperate(row)" class="f12">修改</span>
              </DropdownItem>
              <DropdownItem  >
                <span @click="listDeleteAlert(row)" class="f12">删除</span></DropdownItem>
            </DropdownMenu>
          </Dropdown>

          <Dropdown v-show="[1].includes(row.status)" v-if="aCheck.rightCheck('edit_sale_exchange')">
            <Button size="small" type="text">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem  >
                <span @click="forceEditExplodeOperate(row)" class="f12">强制修改</span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
              :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>
      </div>
    </div>

    <!--查看-->
    <Modal v-model="view.modal" :title="view.modal_title" width="1280">
      <div class="modalForm">
        <Form :label-width="100" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="单号">
                <span>{{ view.item.main_no }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="状态">
                <span :style="getStatusColor(view.item.status)">{{ getStatusText(view.item.status) }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="操作者">
                <span>{{ view.item.operator_name }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="操作日期">
                <span >{{view.item.operate_date}}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20" >
            <Col span="12">
              <FormItem label="备注">
                <span >{{view.item.remark}}</span>
              </FormItem>
            </Col>
          </Row>
          <Row class="mt-10 display-block">
            <Card dis-hover>
              <p slot="title">入库商品</p>
              <Table :columns="add_operate.in_goods_column" :data="view.in_goods_set">
                <template slot-scope="{ row, index }" slot="wholesale_price" >
                  <span>{{row.wholesale_price}}</span>
                </template>

                <template slot-scope="{ row, index }" slot="selling_price">
                  <span>{{row.selling_price}}</span>
                </template>

                <template slot-scope="{ row, index }" slot="goods_num">
                  <span>{{row.goods_num}}</span>
                </template>
                <template slot-scope="{ row, index }" slot="sale_price">
                  <span>{{row.sale_price}}</span>
                </template>
                <template slot-scope="{ row }" slot="stock_all">
                  <span>{{ minus(row.stock_all, row.frozen_stock) }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                  <span class="disabled-color fs-12">------</span>
                </template>
              </Table>
            </Card>
          </Row>
          <Row class="mt2 display-block">
            <Card dis-hover>
              <p slot="title">出库商品</p>
              <Table :columns="add_operate.goods_column" :data="view.out_goods_set">
                <template slot-scope="{ row, index }" slot="goods_num">
                  <span>{{row.goods_num}}</span>
                </template>
                <template slot-scope="{ row, index }" slot="sale_price">
                  <span>{{row.sale_price}}</span>
                </template>
                <template slot-scope="{ row }" slot="stock_all">
                  <span>{{ minus(row.stock_all, row.frozen_stock) }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                  <span class="disabled-color fs-12">------</span>
                </template>
              </Table>
            </Card>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleResetModal(view)">关闭</Button>
        <Button type="primary" v-show="view.item.status===0" :loading="view.modal_loading"
                @click="editExplodeOperate(view.item)" v-if="aCheck.rightCheck('edit_sale_exchange')">修改
        </Button>
        <Button type="warning" v-show="view.item.status===0" :loading="view.modal_loading"
                @click="validOperate(view.item)" v-if="aCheck.rightCheck('valid_sale_exchange')">提交
        </Button>
      </div>
    </Modal>

    <Modal v-model="add_operate.modal" title="商品置换单" width="1280">
      <div class="modalForm">
        <Form ref="addExplode" :model="add_operate.item" :label-width="100" :rules="add_operate.ruleInline"
              label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="单号" prop="main_no">
                <Input v-model="add_operate.item.main_no" disabled></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="备注" prop="remark">
                <Input v-model="add_operate.item.remark"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row class="mt-10 display-block">
            <Card dis-hover>
              <p slot="title">入库商品</p>
              <Button type="primary" slot="extra" size="small" @click="addGoods(2)" class="f12">
                <Icon type="md-add"/>
                添加商品
              </Button>
              <Table :columns="add_operate.in_goods_column" :data="add_operate.in_goods_set">
                <template slot-scope="{ row, index }" slot="wholesale_price" >
                  <Input type="number" v-model="row.wholesale_price" @on-change="inPriceSum(row, index)"/>
                </template>

                <template slot-scope="{ row, index }" slot="selling_price">
                  <Input type="number" v-model="row.selling_price" @on-change="inPriceSum(row, index)"/>
                </template>
                <template slot-scope="{ row, index }" slot="goods_num">
                  <p>
                    <i-input type="number" v-model="row.goods_num" @on-change="inPriceSum(row, index)"/>
                  </p>
                </template>
                <template slot-scope="{ row, index }" slot="sale_price">
                  <p>
                    <i-input type="number" v-model="row.sale_price" @on-change="inPriceSum(row, index)"/>
                  </p>
                </template>
                <template slot-scope="{ row }" slot="stock_all">
                  <span>{{ minus(row.stock_all, row.frozen_stock) }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                  <Button class="fs12" type="text" size="small" @click="deleteInGoodsSelected(row, index)">
                    <Icon type="ios-trash"/>
                    删除
                  </Button>
                </template>
              </Table>
            </Card>
          </Row>
          <Row class="mt2 display-block">
            <Card dis-hover>
              <p slot="title">出库商品</p>
              <Button type="primary" slot="extra" size="small" @click="addGoods(1)" class="f12">
                <Icon type="md-add"/>
                添加商品
              </Button>
              <Table :columns="add_operate.goods_column" :data="add_operate.out_goods_set">
                <template slot-scope="{ row, index }" slot="goods_num">
                  <p>
                    <i-input type="number" v-model="row.goods_num" @on-change="outPriceSum(row, index)"/>
                  </p>
                </template>
                <template slot-scope="{ row, index }" slot="sale_price">
                  <p>
                    <i-input type="number" v-model="row.sale_price" @on-change="outPriceSum(row, index)"/>
                  </p>
                </template>
                <template slot-scope="{ row }" slot="stock_all">
                  <span>{{ minus(row.stock_all, row.frozen_stock) }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                  <Button class="fs12" type="text" size="small" @click="deleteOutGoodsSelected(row, index)">
                    <Icon type="ios-trash"/>
                    删除
                  </Button>
                </template>
              </Table>
            </Card>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="closeAddOperateModal">关闭</Button>
        <Button type="primary" :loading="add_operate.modal_loading" @click="createGoodsExplode('addExplode')">保存
        </Button>
      </div>
    </Modal>

    <Modal v-model="force_operate.modal" title="强制修改商品置换单" width="1280">
      <div class="modalForm">
        <Form ref="addExplode" :model="add_operate.item" :label-width="100" :rules="add_operate.ruleInline"
              label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="单号" prop="main_no">
                <Input v-model="add_operate.item.main_no" disabled></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="备注" prop="remark">
                <Input v-model="add_operate.item.remark"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row class="mt-10 display-block">
            <Card dis-hover>
              <p slot="title">入库商品</p>
              <Table :columns="add_operate.in_goods_column" :data="add_operate.in_goods_set">
                <template slot-scope="{ row, index }" slot="wholesale_price" >
                  <Input type="number" v-model="row.wholesale_price" @on-change="inPriceSum(row, index)"/>
                </template>

                <template slot-scope="{ row, index }" slot="selling_price">
                  <Input type="number" v-model="row.selling_price" @on-change="inPriceSum(row, index)"/>
                </template>
                <template slot-scope="{ row, index }" slot="goods_num">
                  <p>
                    <i-input disabled type="number" v-model="row.goods_num" @on-change="inPriceSum(row, index)"/>
                  </p>
                </template>
                <template slot-scope="{ row, index }" slot="sale_price">
                  <p>
                    <i-input disabled type="number" v-model="row.sale_price" @on-change="inPriceSum(row, index)"/>
                  </p>
                </template>
                <template slot-scope="{ row }" slot="stock_all">
                  <span>{{ minus(row.stock_all, row.frozen_stock) }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                  <span class="disabled-color">------</span>
                </template>
              </Table>
            </Card>
          </Row>
          <Row class="mt2 display-block">
            <Card dis-hover>
              <p slot="title">出库商品</p>
              <Table :columns="add_operate.goods_column" :data="add_operate.out_goods_set">
                <template slot-scope="{ row, index }" slot="goods_num">
                  <p>
                    <i-input disabled type="number" v-model="row.goods_num" @on-change="outPriceSum(row, index)"/>
                  </p>
                </template>
                <template slot-scope="{ row, index }" slot="sale_price">
                  <p>
                    <i-input disabled type="number" v-model="row.sale_price" @on-change="outPriceSum(row, index)"/>
                  </p>
                </template>
                <template slot-scope="{ row }" slot="stock_all">
                  <span>{{ minus(row.stock_all, row.frozen_stock) }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                  <span class="disabled-color">------</span>
                </template>
              </Table>
            </Card>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="closeAddOperateModal">关闭</Button>
        <Button type="primary" :loading="add_operate.modal_loading" @click="forceEditGoodsExplode('addExplode')">保存
        </Button>
      </div>
    </Modal>

    <SelectGoods ref="select_goods" @selected="getSelectedGoods" :select_goods="select_goods"/>

  </div>
</template>

<script>
import {
  apiGetSaleExplodeNewId,
  apiCreateSaleExchange,
  apiGetSaleExchangeList,
  apiGetSaleExchangeDetail,
  apiDeleteSaleExchangeList,
  apiValidSaleExchangeOperate,
  forceEditSaleExchangeApi
} from '@/api/sale/sale';
import NP, {minus} from 'number-precision';
import SelectGoods from '@/components/common/SelectGoods.vue';



export default {
  name: "sale_exchange",
  data() {
    return {
      view: {
        modal: false,
        modal_loading: false,
        modal_title: '商品置换单',
        item: {},
        out_goods_set:[],
        in_goods_set:[],
      },
      list: {
        page_size_key: 'sale_exchange_page_size_key',
        page_size_opts: [10, 15, 20, 30],
        search_item: {
          main_no: '',
        },
        columns: [
          {
            title: '置换单号',
            key: 'main_no',
            slot: 'main_no',
            align: 'center',
            width: 210,
          },
          {
            title: '操作人',
            key: 'operator_name',
            align: 'center',
          },
          {
            title: '摘要',
            key: 'abstract',
            slot: 'abstract',
            minWidth: 260,
            align: 'left',
          },
          {
            title: '操作日期',
            key: 'operate_date',
            slot: 'operate_date',
            align: 'center',
          },
          {
            title: '状态',
            key: 'status',
            slot: 'status',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
            width: 160,
          }
        ],
        data: [],
        size: 10,
      },
      common: {
        view_flag: 1,
        logistics_all: [],
        img_preview_data:{},
      },
      force_operate: {
        modal: false,
      },
      add_operate: {
        modal: false,
        modal_loading: false,
        item: {
          id: 0,
          main_no: '',
          remark: '',
        },
        ruleInline:{},
        in_goods_column:[
          {
            title: '商品编号',
            key: 'part_no',
            align: 'center',
          },
          {
            title: '商品名称',
            key: 'part_name',
            minWidth:200,
            align: 'left',
          },
          {
            title: '批发价',
            key: 'wholesale_price',
            slot: 'wholesale_price',
            align: 'center',
          },
          {
            title: '销售价',
            key: 'selling_price',
            slot: 'selling_price',
            align: 'center',
          },
          {
            title: '操作数量',
            key: 'goods_num',
            slot: 'goods_num',
            align: 'center',
          },
          {
            title: '单价',
            key: 'sale_price',
            slot: 'sale_price',
            align: 'center',
          },
          {
            title: '金额',
            key: 'total',
            align: 'center',
          },
          {
            title: '可用库存',
            slot: 'stock_all',
            key: 'stock_all',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            slot: 'action',
            align: 'center',
          }
        ],
        goods_column:[
          {
            title: '商品编号',
            key: 'part_no',
            align: 'center',
          },
          {
            title: '商品名称',
            key: 'part_name',
            minWidth:200,
            align: 'left',
          },
          {
            title: '操作数量',
            key: 'goods_num',
            slot: 'goods_num',
            align: 'center',
          },
          {
            title: '销售单价',
            key: 'sale_price',
            slot: 'sale_price',
            align: 'center',
          },
          {
            title: '金额',
            key: 'total',
            align: 'center',
          },
          {
            title: '可用库存',
            slot: 'stock_all',
            key: 'stock_all',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            slot: 'action',
            align: 'center',
          }
        ],
        out_goods_set:[],
        in_goods_set:[],
        operate_flag:0,
      },
      select_goods: {
        modal: false,
        count_flag: 0,
        goods_type: 0,//物料1 商品2 3半成品  4虚拟商品
        client_id: 0,
      },
    }

  },
  methods: {
    listDeleteAlert(row) {
      this.$Modal.confirm({
        title: "删除商品置换单信息",
        content: '确定删除商品置换单信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteListInfo(row.id);
        },
        onCancel: () => {
        }
      });
    },
    deleteListInfo(id) {
      apiDeleteSaleExchangeList({id: id}).then(res => {
        if (res.data.err_no == 0) {
          this.$Message.success(res.data.results);
          this.getMainList();
        } else {
          this.$Message.error(res.data.err_msg);
        }
      })
    },
    closeAddOperateModal() {
      this.add_operate.modal = false;
      this.add_operate.operate_flag = 0;
      this.force_operate.modal = false;
    },
    deleteInGoodsSelected(row, index) {
      this.add_operate.in_goods_set.splice(index, 1);
    },
    deleteOutGoodsSelected(row, index) {
      this.add_operate.out_goods_set.splice(index, 1);
    },
    getSelectedGoods(val) {
      //获取选中的商品
      let selected_goods = val;
      let setted_goods = [];
      if (this.add_operate.operate_flag ==1) {
        setted_goods= this.add_operate.out_goods_set;
      } else {
        setted_goods = this.add_operate.in_goods_set;
      }


      if (selected_goods.length) {
        for (let i = 0, len = selected_goods.length; i < len; i++) {
          let added_flag = false;

          //排除已添加的
          for (let j = 0, jlen = setted_goods.length; j < jlen; j++) {
            if (setted_goods[j].id == selected_goods[i].id) {
              added_flag = true;
              break;
            }
          }

          //如果未添加
          if (!added_flag) {
            selected_goods[i].total = 0;
            selected_goods[i].goods_num = '';
            selected_goods[i].wholesale_price = parseFloat(selected_goods[i].wholesale_price);
            selected_goods[i].selling_price = parseFloat(selected_goods[i].selling_price);
            selected_goods[i].sale_price = parseFloat(selected_goods[i].cost_price);
            setted_goods.push(selected_goods[i]);
          }
        }
      }
    },
    //添加商品
    addGoods(operate_flag) {
      this.add_operate.operate_flag = operate_flag;
      this.$refs.select_goods.showGoodsList();
    },
    inPriceSum(row, index) {
      let goods_set = [];
      goods_set= this.add_operate.in_goods_set;

      if (isNaN(row.goods_num) || isNaN(row.sale_price)) {
        goods_set[index].total = 0;
      } else {
        //赋值
        goods_set[index].goods_num = row.goods_num;
        goods_set[index].sale_price = row.sale_price;
        let total = NP.times(row.goods_num, row.sale_price);
        goods_set[index].total = total;
        row.total = total;
      }

      if (!isNaN(row.selling_price) ) {
        goods_set[index].selling_price = row.selling_price;
      }

      if (!isNaN(row.wholesale_price)) {
        //赋值
        goods_set[index].wholesale_price = row.wholesale_price;
      }
    },
    outPriceSum(row, index) {
      let goods_set = [];
      goods_set= this.add_operate.out_goods_set;

      if (isNaN(row.goods_num) || isNaN(row.sale_price)) {
        goods_set[index].total = 0;
      } else {
        //赋值
        goods_set[index].goods_num = row.goods_num;
        goods_set[index].sale_price = row.sale_price;
        let total = NP.times(row.goods_num, row.sale_price);
        goods_set[index].total = total;
        row.total = total;
      }
    },
    minus(a, b) {
      return NP.minus(a, b)
    },
    createOperate(name) {
      this.$refs[name].resetFields();

      //创建商品信息
      this.add_operate.modal = true;
      this.add_operate.modal_loading = false;
      this.add_operate.out_goods_set = [];
      this.add_operate.in_goods_set = [];

      this.add_operate.item.id = 0;

      apiGetSaleExplodeNewId().then((res) => {
        if (res.data.err_no == 0) {
          this.add_operate.item.main_no = res.data.results;
        }
      });
    },
    forceEditGoodsExplode(name) {
      this.add_operate.modal_loading = true;

      let param = {
        id: this.add_operate.item.id,
        main_no: this.add_operate.item.main_no,
        remark: this.add_operate.item.remark,
        in_goods_set: this.add_operate.in_goods_set,
        out_goods_set: this.add_operate.out_goods_set,
      }
      forceEditSaleExchangeApi(param).then(res => {
        if (res.data.err_no == 0) {
          this.$Message.success('操作成功');
          this.force_operate.modal = false;
          this.$refs[name].resetFields();
          this.getMainList();
        } else {
          this.$Message.error(res.data.err_msg);
        }
        this.add_operate.modal_loading = false;
      }).catch((res) => {
        this.$Message.error('操作错误');
        this.add_operate.modal_loading = false;
      });
    },
    createGoodsExplode(name) {
      this.add_operate.modal_loading = true;

      if (this.add_operate.out_goods_set.length < 1) {
        this.$Message.error('请添加出库商品');
        this.add_operate.modal_loading = false;
        return;
      }

      for (let i=0; i<this.add_operate.out_goods_set.length; i++) {
        if (this.add_operate.out_goods_set[i].total <= 0) {
          this.$Message.error('出库商品总价小于等于0');
          this.add_operate.modal_loading = false;
          return;
        }

        if (parseFloat(this.add_operate.out_goods_set[i].goods_num) > this.add_operate.out_goods_set[i].stock_all) {
          this.$Message.error('出库商品数量不能大于库存数量');
          this.add_operate.modal_loading = false;
          return;
        }
      }

      if (this.add_operate.in_goods_set.length < 1) {
        this.$Message.error('请添加入库商品');
        this.add_operate.modal_loading = false;
        return;
      }

      for (let i=0; i<this.add_operate.in_goods_set.length; i++) {
        if (this.add_operate.in_goods_set[i].total <= 0) {
          this.$Message.error('入库商品数量不能小于等于0');
          this.add_operate.modal_loading = false;
          return;
        }
      }

      this.$refs[name].validate((valid) => {
        if (valid) {
          let param = {
            id: this.add_operate.item.id,
            main_no: this.add_operate.item.main_no,
            remark: this.add_operate.item.remark,
            in_goods_set: this.add_operate.in_goods_set,
            out_goods_set: this.add_operate.out_goods_set,
          }
          apiCreateSaleExchange(param).then(res => {
            if (res.data.err_no == 0) {
              this.$Message.success('操作成功');
              this.add_operate.modal = false;
              this.$refs[name].resetFields();
              this.getMainList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
            this.add_operate.modal_loading = false;
          }).catch((res) => {
            this.$Message.error('操作错误');
            this.add_operate.modal_loading = false;
          });
        } else {
          this.add_operate.modal_loading = false;
        }
      });
    },

    validOperate(row) { //发货完成
      this.view.modal_loading = true;
      let param = {
        id: row.id,
        main_no: row.main_no,
      };
      apiValidSaleExchangeOperate(param).then((res) => {
        if (res.data.err_no === 0) {
          this.$Message.success('操作成功');
          this.view.modal = false;
          this.getMainList();
        } else {
          this.$Message.success('操作失败');
        }

        this.view.modal_loading = false;
      });
    },
    handleResetModal() {
      this.view.modal = false;
      this.view.modal_loading = false;
    },
    forceEditExplodeOperate(row) {
      this.view.modal = false;
      this.view.modal_loading = false;

      this.add_operate.item = row;
      this.add_operate.out_goods_set = [];
      this.add_operate.in_goods_set = [];
      //获取出入库商品详情
      let param = {
        id: row.id
      };
      apiGetSaleExchangeDetail(param).then((res) => {
        if (res.data.err_no == 0) {
          for (let i = 0; i < res.data.results.length; i++) {
            res.data.results[i].sale_price = parseFloat(res.data.results[i].sale_price);
            res.data.results[i].goods_num = parseFloat(res.data.results[i].goods_num);
            res.data.results[i].total = parseFloat(res.data.results[i].total);
            if (res.data.results[i].type == 1) {
              //1表示出库商品
              this.add_operate.out_goods_set.push(res.data.results[i]);
            } else {
              res.data.results[i].selling_price = parseFloat(res.data.results[i].selling_price);
              res.data.results[i].wholesale_price = parseFloat(res.data.results[i].wholesale_price);
              this.add_operate.in_goods_set.push(res.data.results[i]);
            }
          }
        }

        this.add_operate.modal_loading = false;
        this.force_operate.modal = true;
      });
    },
    editExplodeOperate(row) {
      this.view.modal = false;
      this.view.modal_loading = false;

      this.add_operate.item = row;
      this.add_operate.out_goods_set = [];
      this.add_operate.in_goods_set = [];
      //获取出入库商品详情
      let param = {
        id: row.id
      };
      apiGetSaleExchangeDetail(param).then((res) => {
        if (res.data.err_no == 0) {
          for (let i = 0; i < res.data.results.length; i++) {
            res.data.results[i].sale_price = parseFloat(res.data.results[i].sale_price);
            res.data.results[i].goods_num = parseFloat(res.data.results[i].goods_num);
            res.data.results[i].total = parseFloat(res.data.results[i].total);
            if (res.data.results[i].type == 1) {
              //1表示出库商品
              this.add_operate.out_goods_set.push(res.data.results[i]);
            } else {
              res.data.results[i].selling_price = parseFloat(res.data.results[i].selling_price);
              res.data.results[i].wholesale_price = parseFloat(res.data.results[i].wholesale_price);
              this.add_operate.in_goods_set.push(res.data.results[i]);
            }
          }
        }

        this.add_operate.modal_loading = false;
        this.add_operate.modal = true;
      });
    },

    checkOrderInfo(row) {
      this.view.item = row;
      this.view.out_goods_set = [];
      this.view.in_goods_set = [];
      this.view.modal_loading = false;

      //获取出入库商品详情
      let param = {
        id: row.id
      };
      apiGetSaleExchangeDetail(param).then((res) => {
        if (res.data.err_no == 0) {
          for (let i = 0; i < res.data.results.length; i++) {
            res.data.results[i].sale_price = parseFloat(res.data.results[i].sale_price);
            res.data.results[i].goods_num = parseFloat(res.data.results[i].goods_num);
            res.data.results[i].total = parseFloat(res.data.results[i].total);
            if (res.data.results[i].type == 1) {
              //1表示出库商品
              this.view.out_goods_set.push(res.data.results[i]);
            } else {
              res.data.results[i].selling_price = parseFloat(res.data.results[i].selling_price);
              res.data.results[i].wholesale_price = parseFloat(res.data.results[i].wholesale_price);
              this.view.in_goods_set.push(res.data.results[i]);
            }
          }
        }

        // this.view.item.goods_setted = res.data.results.outInfo.outGoods;
        // this.view.item.address = res.data.results.saleInfo.address;
        this.view.modal = true;
      });
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.list.page = 1;
      this.getMainList();
    },
    timeChange(dateRange) {
      if (dateRange[0] && dateRange[1]) {
        this.list.search_item.created_at = dateRange;
      } else {
        this.list.search_item.created_at = "";
      }

      this.searchSubmit();
    },
    getStatusText(index) {
      let rs = '';
      switch (index) {
        case 0:
          rs = '待提交';
          break;
        case 1:
          rs = '已提交';
          break;

      }

      return rs;
    },
    getStatusColor(index) {
      let rs = '';
      switch (index) {
        case 0:
          rs = 'color:#ed4014';
          break;
        case 1:
          rs = 'color:#2775ff';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    searchSubmit() {
      this.list.page = 1;
      this.getMainList();
    },
    getMainList() {
      let param = {
        page: this.list.page,
        main_no: this.list.search_item.main_no,
        // sale_no: this.list.search_item.sale_no,
        // out_no: this.list.search_item.out_no,
        // created_at: this.list.search_item.created_at,
        size: this.list.size,
      };

      apiGetSaleExchangeList(param).then((res) => {
        this.list.data = res.data.results.list;
        this.list.total = res.data.results.total;
        this.list.size = res.data.results.size;
      });
    },
    pageSizeChange(page_size) {
      this.list.size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getMainList();
    },
    changePage(page) {
      this.list.page = page;
      this.getMainList();
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },
  },
  mounted() {
    this.setSelectedPageSize();

    this.getMainList();
  },
  components: {
    SelectGoods
  },
  created() {
    this.aCheck.plateName = 'sale_manage';
  },
}
</script>

<style scoped>
.select-flag {
  font-size: 16px;
  font-weight: 500;
}

#goods_explode .ivu-btn-small {
  font-size: 12px !important;
}
</style>
<style>
#goods_explode .ivu-card {
  background-color: #fafafa;
}

#goods_explode .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#goods_explode .ivu-form-item {
  margin-bottom: 0;
}

#list .page {
  margin-top: 16px;
}

.main-list .ivu-table-overflowX, .ivu-table-tip {
  overflow-x: hidden;
}

.main-list .ivu-table-wrapper {
  overflow: visible;
}

.panel-header {
  display:flex;
  align-items: center;
  justify-content: space-between;
}
</style>
