<template>
  <div id="sale-list">
    <div id="panel-header">
      <span class="top-title">销售列表</span>
<!--      <Button type="info" class="client_export fs-12" @click="exportSubmit('sale_export_item')" icon="ios-download-outline">-->
<!--        导出-->
<!--      </Button>-->
      <Button type="primary" class="client_create" @click="create('clientFormItem')"
              v-if="aCheck.rightCheck('edit_sales_list')">添加销售单
      </Button>
    </div>

    <!--列表筛选条件-->
    <div id="filter-bar">
      <Card dis-hover >
        <Form ref="filter_bar" :model="filter_bar" inline>
          <FormItem prop="sale_no">
            <Input @on-blur="searchSubmit()"  v-model="filter_bar.sale_no"  placeholder="请填写销售单号" class="w-200"/>
          </FormItem>

          <FormItem prop="client">
            <Select @on-change="searchSubmit()" v-model="filter_bar.client"  placeholder="请选择销售客户" filterable class="w-200">
              <Option v-for="item in clientList" :value="item.client_id" :key="item.client_id">{{item.client_name}}</Option>
            </Select>
          </FormItem>

          <FormItem prop="status">
            <Select @on-change="searchSubmit()" v-model="filter_bar.status" multiple  placeholder="请选择销售状态" filterable class="w-200">
              <Option v-for="item in all_sale_status" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem prop="pay_status">
            <Select @on-change="searchSubmit()" v-model="filter_bar.pay_status" multiple  placeholder="请选择收款状态" filterable class="w-200">
              <Option v-for="item in all_sale_pay_status" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem prop="sale_date">
            <DatePicker  type="daterange" @on-change="timeChange" placement="bottom-end"
                            placeholder="请选择销售时间" v-model="filter_bar.sale_date" class="w-200"></DatePicker>
          </FormItem>
          <FormItem prop="include_tax">
            <Select @on-change="searchSubmit()" v-model="filter_bar.include_tax"  placeholder="请选择是否含税" filterable class="w-200">
              <Option v-for="item in taxList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem>
            <Button type="text" class="main-font-color" @click="cancelSearch('filter_bar')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <!--列表-->
    <div id="list" class="sale_list">
      <ColumnsSet ref="columns_set" :list_more="list_more"/>
      <Table :loading="common.list_loading" :columns="list_more.columns" :data="list.data">
        <template slot-scope="{ row }" slot="sale_no">
          <strong @click="checkOrderInfo(row)" class="pointer">{{ row.sale_no }}</strong>
        </template>
        <template slot-scope="{ row }" slot="abstract">
          <span class="fs-12"><span class="money-color fw-bold" v-show="row.include_tax == 1">[票]</span>{{ row.abstract }}</span>
        </template>

        <template slot-scope="{ row }" slot="status">
          <Tag :style="getStatusColorTg(row.status)" class="white-font-color">{{ getStatusText(row.status) }}</Tag>
        </template>

        <template slot-scope="{ row }" slot="pay_status">
          <span class="pointer" :style="getPayStatusColor(row.pay_status)" @click="setPayStatusFinish(row, 'list')">{{ getPayStatusText(row.pay_status) }}</span>
        </template>

        <template slot-scope="{ row }" slot="sale_date">
          <span>{{ row.sale_date.substring(0, 10) }}</span>
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <!--待审核情况下显示-->
          <Button v-show="[1].includes(row.status)" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="checkOrderInfo(row)" v-if="aCheck.rightCheck('valid_sales_list')">
            <Icon type="ios-checkmark-circle"/>
            提交
          </Button>
          <Button v-show="[2,3,4,5,6,8,10].includes(row.status)" type="text" size="small"
                  :style="[5].includes(row.status) ? 'color:#c5c8ce' : 'color:#19be6b'" @click="checkOrderInfo(row)">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>
          <!--审核通过情况下显示-->
          <Button v-show="[2].includes(row.status)" type="text" size="small" style="color:#ff9900;"
                  @click="checkOrderInfo(row)" v-if="aCheck.rightCheck('operate_sales_list')">
            <Icon type="ios-close-circle"/>
            取消
          </Button>
          <!--入库情况下显示-->
          <Button v-show="[7,9,11].includes(row.status)" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="checkOrderInfo(row)" v-if="aCheck.rightCheck('valid_sales_list')">
            <Icon type="ios-checkmark-circle"/>
            客户收货
          </Button>
          <Dropdown v-show="[1,7].includes(row.status)">
            <Button size="small" type="text">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem v-show="[1].includes(row.status)" v-if="aCheck.rightCheck('edit_sales_list')">
                <span @click="listEdit(row)" class="f12">修改订单</span>
              </DropdownItem>
              <DropdownItem v-show="[1].includes(row.status)" v-if="aCheck.rightCheck('edit_sales_list')">
                <span @click="listDeleteAlert(row)" class="f12">删除订单</span>
              </DropdownItem>
<!--              <DropdownItem v-show="[7].includes(row.status)" v-if="aCheck.rightCheck('operate_sales_list')">-->
<!--                <span @click="saleReturnOperate(row)" class="f12">退货退款</span>-->
<!--              </DropdownItem>-->
<!--              <DropdownItem v-show="[7].includes(row.status)" v-if="aCheck.rightCheck('operate_sales_list')">-->
<!--                <span @click="moneyReturnOperate(row)" class="f12">仅退款，不退货</span>-->
<!--              </DropdownItem>-->
            </DropdownMenu>
          </Dropdown>

          <Dropdown v-show="[4].includes(row.status)" v-if="aCheck.rightCheck('force_sales_list')">
            <Button size="small" type="text">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem>
                <span @click="forceDelete(row)" class="f12">强制删除</span></DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
              :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>
      </div>

    </div>

    <!--添加修改销售订单-->
    <Modal v-model="add_edit.modal" :title="add_edit.model_title" width="1280">
      <div class="modalForm">
        <Form v-show="!common.view_loading" ref="clientFormItem" :model="clientFormItem" :label-width="100" :rules="ruleInline" label-colon>
          <Row :gutter="10">
            <Col span="8">
              <FormItem label="销售单号" prop="sale_no">
                <Input v-model="clientFormItem.sale_no" disabled placeholder="请填写销售单号"></Input>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="客户" prop="client_id">
                <Select v-model="clientFormItem.client_id" placeholder="选择客户" filterable @on-change="getClientAddress(clientFormItem.client_id)" style="width: 280px;">
                  <Option v-for="item in clientList" :value="item.client_id">{{item.client_name}}</Option>
                </Select>
                <Icon type="md-add" class="ml-10 main-font-color fw-bold pointer" @click="addClients"/>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="销售日期" prop="sale_date">
                <DatePicker :value="clientFormItem.sale_date" type="datetime"
                            format="yyyy-MM-dd HH:mm:ss"
                            placeholder="请填写销售时间(不填，默认当前时间)" style="width: 307px"
                            @on-change="getSaleDateTime"></DatePicker>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="10">
            <Col span="8">
              <FormItem label="含税" prop="include_tax">
                <Select v-model="clientFormItem.include_tax" placeholder="请选择是否含税">
                  <Option v-for="item in taxList" :value="item.id">{{ item.name }}</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="备注" prop="remark">
                <Input v-model="clientFormItem.remark" placeholder="请填写备注"></Input>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="提货方式" prop="pick_way">
                <Select v-model="clientFormItem.pick_way" @on-change="pickWayChange" placeholder="选择提货方式">
                  <Option v-for="item in pick_way" :value="item.id">{{item.name}}
                  </Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="10" v-if="clientFormItem.pick_way ==2">
            <Col span="8">
              <FormItem label="物流名称" prop="logistics_id" :rules="{required: true, message: '物流名称必填'}">
                <Select v-model="clientFormItem.logistics_id" filterable placeholder="请选择物流名称">
                  <Option v-for="i in logisticsList" :value="i.id">{{ i.client_name }}</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="物流单号" prop="logistics_no" :rules="{required: true, message: '物流单号必填', trigger: 'blur'}">
                <Input v-model="clientFormItem.logistics_no" placeholder="请填写物流单号"></Input>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="物流运费" prop="logistics_pay">
                <Input type="number" v-model="clientFormItem.logistics_pay" placeholder="请填写物流运费"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="10">
            <Col span="8">
              <FormItem label="凭证" prop="certificate">
                <!-- fileList为回传属性    uploadParam用来传值                -->
                <ImageUpload ref="image_upload" @fileList="getFileList" :uploadParam="clientFormItem.uploadParam"
                             :key="clientFormItem.uploadParam.key"/>
              </FormItem>
            </Col>
          </Row>

          <Row class="mt2 display-block">
            <Card dis-hover>
              <p slot="title">销售明细</p>
              <Button type="primary" slot="extra" size="small" @click="addGoods()" class="f12">
                <Icon type="md-add"/>
                添加商品
              </Button>
              <Table :columns="table_columns_modal" :data="clientFormItem.goods_setted" show-summary
                     :summary-method="addGoodsHandleSummary">
                <template slot-scope="{ row, index }" slot="part_name">
                  <span class="money-color fw-bold fs-12" v-show="row.free_flag==1">[赠]</span>
                  <span>{{row.part_name}}</span>
                </template>
                <template slot-scope="{ row, index }" slot="sale_num">
                  <p>
                    <i-input type="number" v-model="row.sale_num" @on-change="priceSum(row, index)"/>
                  </p>
                </template>
                <template slot-scope="{ row, index }" slot="sale_price">
                  <p>
                    <i-input type="number" v-model="row.sale_price" @on-change="priceSum(row, index)"/>
                  </p>
                </template>
                <template slot-scope="{ row }" slot="stock_all">
                  <span>{{ minus(row.stock_all, row.frozen_stock) }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                  <Button class="fs12" type="text" size="small" @click="deleteGoodsSelected(row, index)">
                    <Icon type="ios-trash"/>
                    删除
                  </Button>
                  <Button v-show="row.free_flag==0" class="fs12" type="text" size="small" @click="freeGoodsSelected(row, index)">
                    <Icon type="ios-pizza"/>
                    赠品
                  </Button>
                  <Button v-show="row.free_flag==1" class="fs12" type="text" size="small" @click="chargeGoodsSelected(row, index)">
                    <Icon type="ios-pizza"/>
                    取消
                  </Button>
                </template>
              </Table>
            </Card>
          </Row>

          <Row :gutter="10" class="mt-10">
            <Col span="4">
              <FormItem label="优惠" prop="discount_amount" class="mb-0">
                <Input @on-change="setOrderPay" v-model="clientFormItem.discount_amount" placeholder="请填写优惠" type="number"></Input>
              </FormItem>
            </Col>
            <Col span="3">
              <FormItem label="物流运费" class="mb-0">
                <strong class="main-font-color">{{clientFormItem.logistics_pay}}</strong>
              </FormItem>
            </Col>
            <Col span="3">
              <FormItem label="商品金额" prop="count_price" class="mb-0">
                <strong class="main-font-color">{{clientFormItem.count_price}}</strong>
              </FormItem>
            </Col>
            <Col span="3">
              <FormItem label="订单总额" prop="goods_pay" class="mb-0">
                <strong class="main-font-color">{{ clientFormItem.goods_pay }}</strong>
              </FormItem>
            </Col>
            <Col span="5">
              <FormItem label="实收金额" prop="real_pay" class="mb-0">
<!--                <Input v-model="clientFormItem.real_pay" placeholder="请填写实收金额" type="number"></Input>-->
                <div style="display: flex;align-items: center;">
                  <Input v-model="clientFormItem.real_pay" placeholder="请填写实收金额" type="number"></Input>
                  <Icon class="main-font-color ml-5 pointer" type="md-refresh" @click="setRealPay"/>
                </div>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="收款说明" prop="pay_desc">
                <Input v-model="clientFormItem.pay_desc" placeholder="请填写收款说明"></Input>
                <div v-for="item in common.pay_desc_list" @click="setPayDesc(clientFormItem, item.name)" class="ivu-tag ivu-tag-size-default ivu-tag-default ivu-tag-checked pointer">
                  <span class="c-515a6e">{{ item.name }}</span>
                </div>
              </FormItem>
            </Col>
          </Row>
        </Form>
        <Spin fix v-show="common.view_loading" size="large"></Spin>
      </div>
      <div slot="footer">
        <Button @click="handleReset('clientFormItem')">关闭</Button>
        <Button type="primary" :loading="modal_loading.add_edit" @click="createSaleOrder('clientFormItem')">保存</Button>
      </div>
    </Modal>

    <!--审核-->
    <Modal v-model="check_order.modal" :title="check_order.title" width="1280">
      <div class="modalForm">
        <Form v-show="!common.view_loading" :model="clientFormItem" :label-width="100" label-colon>
          <Row :gutter="10">
            <Col span="8">
              <FormItem label="销售单号" prop="sale_no">
                <span>{{ clientFormItem.sale_no }}</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="客户" prop="client_id">
                <span v-for="item in clientList" v-show="item.client_id===clientFormItem.client_id && (clientFormItem.client_name=item.client_name)">{{ item.client_name }}</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="销售日期" prop="sale_date">
                <span>{{ clientFormItem.sale_date }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="10">
            <Col span="8">
              <FormItem label="含税" prop="include_tax">
                <span v-if="clientFormItem.include_tax == 1" class="money-color">是</span>
                <span v-else>否</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="开票状态" prop="invoicing_status">
                <span v-if="clientFormItem.invoicing_status == 1" class="main-font-color">已开票</span>
                <span v-if="clientFormItem.invoicing_status == 2" class="danger-color">待开票</span>
                <span v-if="clientFormItem.invoicing_status == 0">暂无</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="提货方式" prop="pick_way">
                <span v-for="item in pick_way" v-if="clientFormItem.pick_way == item.id" >{{ item.name }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="10" v-if="clientFormItem.pick_way==2">
            <Col span="8">
              <FormItem label="物流商" prop="logistics_id">
                <span v-for="i in logisticsList" v-if="clientFormItem.logistics_id == i.id">{{ i.client_name }}({{ i.contact_person }})</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="物流单号" prop="logistics_no">
                <span>{{ clientFormItem.logistics_no }}</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="物流费用" prop="logistics_pay">
                <span>{{ clientFormItem.logistics_pay }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="10">
            <Col span="8">
              <FormItem label="备注" prop="remark">
                <span>{{ clientFormItem.remark }}</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="单据状态" prop="status">
                <Tag :style="getStatusColorTg(clientFormItem.status)" class="white-font-color">{{ getStatusText(clientFormItem.status) }}</Tag>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="收款状态" prop="status">
                <span :style="getPayStatusColor(clientFormItem.pay_status)">{{ getPayStatusText(clientFormItem.pay_status) }}</span>
<!--                <Button type="text" @click="setPayStatusFinish(clientFormItem)" class="status-main fs-12" v-if="clientFormItem.pay_status == 2 && ![1,5].includes(clientFormItem.status)">-->
<!--                  <Icon type="ios-checkmark-circle"/>-->
<!--                  设为已收款状态-->
<!--                </Button>-->
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="10">
            <Col span="8">
              <FormItem label="凭证" prop="flow_time">
                <div class="pointer image-upload-list" v-if="clientFormItem.certificate" v-for="(item, key)  in clientFormItem.certificate">
                  <img :src="item.url" alt="" @click="imgShow(key, clientFormItem.certificate)" style="width:100%;height:100%">
                </div>
              </FormItem>
            </Col>

            <Col span="8" v-if="parseInt(special_rights_gross_profit) == 1">
              <FormItem label="毛利">
                <strong class="money-color">{{ clientFormItem.gross_profit}}</strong>
              </FormItem>
            </Col>
          </Row>

          <Row class="display-block">
            <Card dis-hover >
              <div>
                <span style="margin:0 10px" :class="common.view_flag == 1 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(1)">销售商品</span>
                <span style="margin:0 10px" :class="common.view_flag == 3 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(3)">实收金额记录</span>
                <span style="margin:0 10px" v-show="[9,10].includes(clientFormItem.status)" :class="common.view_flag == 2 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(2)">退货明细</span>
                <span style="margin:0 10px" v-show="[9,10,11].includes(clientFormItem.status)" :class="common.view_flag == 4 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(4)">退款金额记录</span>

                <Divider/>
              </div>
              <div v-show="common.view_flag == 1">
                <Table :columns="table_columns_modal" :data="clientFormItem.goods_setted" show-summary
                       :summary-method="addGoodsHandleSummary">
                  <template slot-scope="{ row, index }" slot="part_name">
                    <span class="money-color fw-bold fs-12" v-show="row.free_flag==1">[赠]</span>
                    <span>{{row.part_name}}</span>
                  </template>
                  <template slot-scope="{ row, index }" slot="sale_num">
                    <p>
                      <span>{{ row.sale_num }}</span>
                    </p>
                  </template>
                  <template slot-scope="{ row }" slot="stock_all">
                    <span>{{minus(row.stock_all, row.frozen_stock)}}</span>
                  </template>
                  <template slot-scope="{ row, index }" slot="sale_price">
                    <span>{{ row.sale_price }}</span>
                  </template>
                  <template slot-scope="{ row, index }" slot="action">
                    <span class="disabled-color fs-12">------</span>
                  </template>
                </Table>
              </div>
              <div v-show="common.view_flag == 2 && [9,10].includes(clientFormItem.status)">
                <Table :columns="table_columns_modal" :data="clientFormItem.return_goods_set" show-summary
                       :summary-method="addGoodsHandleSummary">
                  <template slot-scope="{ row }" slot="thumbnail_url">
                    <p><img v-show="row.thumbnail_url" class="preview-img" :src="row.thumbnail_url" alt=""></p>
                  </template>
                  <template slot-scope="{ row, index }" slot="sale_num">
                    <p>
                      <span>{{ row.sale_num }}</span>
                    </p>
                  </template>
                  <template slot-scope="{ row }" slot="stock_all">
                    <span>{{minus(row.stock_all, row.frozen_stock)}}</span>
                  </template>
                  <template slot-scope="{ row, index }" slot="sale_price">
                    <span>{{ row.sale_price }}</span>
                  </template>
                  <template slot-scope="{ row, index }" slot="action">
                    <span class="disabled-color fs-12">------</span>
                  </template>
                </Table>
              </div>
              <div v-show="common.view_flag == 3">
                <Table :columns="common.real_pay_columns" :data="clientFormItem.flow_info">
                  <template slot-scope="{ row, index }" slot="money">
                    <strong>{{ row.money }}</strong>
                  </template>
                </Table>
              </div>
              <div v-show="common.view_flag == 4">
                <Table :columns="common.real_pay_columns" :data="clientFormItem.return_flow_info">
                  <template slot-scope="{ row, index }" slot="money">
                    <strong>{{ row.money }}</strong>
                  </template>
                </Table>
              </div>
            </Card>
          </Row>

          <div class="" style="display:flex;justify-content: space-around;">
            <FormItem label="优惠" prop="discount_amount" class="mb-0">
              <span>{{ parseFloat(clientFormItem.discount_amount) }}</span>
            </FormItem>
            <FormItem label="物流运费" prop="logistics_pay" class="mb-0">
              <span>{{ parseFloat(clientFormItem.logistics_pay) }}</span>
            </FormItem>
<!--            <FormItem label="销售金额" prop="count_price" class="mb-0">-->
<!--              <strong class="main-font-color">{{ clientFormItem.count_price }}</strong>-->
<!--            </FormItem>-->

            <FormItem label="销售总额" prop="goods_pay" class="mb-0">
              <strong class="main-font-color">{{ clientFormItem.goods_pay }}</strong>
            </FormItem>

            <FormItem label="本单实收" prop="goods_pay" class="mb-0">
              <strong class="main-font-color">{{ clientFormItem.real_pay }}</strong>
            </FormItem>

            <FormItem label="本单欠款" prop="to_pay" class="mb-0">
              <strong class="money-color">{{ clientFormItem.to_pay }}</strong>
            </FormItem>

<!--            <FormItem label="实收金额" prop="real_pay" class="mb-0">-->
<!--              <strong class="main-font-color">{{ clientFormItem.real_pay }}</strong>-->
<!--              <span v-show="clientFormItem.status == 1 && clientFormItem.pay_desc" class="fs-12 ml-5">({{clientFormItem.pay_desc}})</span>-->
<!--            </FormItem>-->

<!--            <FormItem label="计划退款金额" prop="goods_pay" class="mb-0" v-show="[6,9,10,11].includes(clientFormItem.status)">-->
<!--              <strong class="main-font-color">{{ clientFormItem.return_cost }}</strong>-->
<!--            </FormItem>-->
          </div>
        </Form>
        <Spin fix v-show="common.view_loading" size="large"></Spin>
      </div>
      <div slot="footer">
        <Button @click="handleResetOrderModal(clientFormItem)">关闭</Button>
        <Button type="primary" v-show="clientFormItem.status==1" @click="showEditSaleOrder(clientFormItem)"
                v-if="aCheck.rightCheck('edit_sales_list')">
          修改订单
        </Button>
        <Button type="success" v-show="clientFormItem.status==1" :loading="modal_loading.success" @click="passSaleOrder(clientFormItem)" v-if="aCheck.rightCheck('valid_sales_list')">
          提交
        </Button>
        <Button type="info" v-show="clientFormItem.invoicing_status==2 && clientFormItem.status!=1" :loading="modal_loading.success" v-if="aCheck.rightCheck('operate_sales_list')"
                @click="setInvoicingStatus(clientFormItem)">开票
        </Button>

        <a target="_blank" style="margin:0 8px;" v-show="clientFormItem.status!=1" :href="'/Print?printType=sale&no='+clientFormItem.id" v-if="aCheck.rightCheck('operate_sales_list')">
          <Button type="primary">打印预览</Button>
        </a>

        <Button type="success" v-show="[0,1,2].includes(clientFormItem.pay_status) && clientFormItem.status!=1" @click="paySaleOrder(clientFormItem)" v-if="aCheck.rightCheck('operate_sales_list')">
          收款
        </Button>

        <Button type="warning" v-show="[2].includes(clientFormItem.status)" :loading="modal_loading.success" @click="cancelSaleOrder(clientFormItem)" v-if="aCheck.rightCheck('operate_sales_list')">
          取消订单
        </Button>

        <Button type="info" v-show="[7,9,11].includes(clientFormItem.status)" :loading="modal_loading.success" @click="finishSaleOrder(clientFormItem)" v-if="aCheck.rightCheck('operate_sales_list')">
          客户确认收货
        </Button>

      </div>
    </Modal>

    <!-- 导出  -->
    <Modal v-model="sale_export.modal" :title="sale_export.title" width="660">
      <div class="modalForm">
        <Form ref="sale_export_item" :model="sale_export.item" :label-width="100" label-colon>
          <!--需填写退货理由，物流 付费方式 运费 -->
          <Row :gutter="20" class="mt-20">
            <Col span="18" offset="3">
              <FormItem label="导出标题" prop="subject">
                <Input v-model="sale_export.item.subject" placeholder="请填写导出标题" type="text"
                       maxlength="200"></Input>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleResetExportModal('sale_export_item')">关闭</Button>
        <Button type="primary" :loading="sale_export.modal_loading" @click="exportOperate">
          导出
        </Button>
      </div>
    </Modal>

    <!--销售收款-->
    <Modal v-model="pay_sale.modal" title="收款操作" width="800">
      <div class="modalForm">
        <Form ref="pay_sale_item" :model="pay_sale.item" :label-width="100" label-colon>
          <Row :gutter="20">
            <Col span="12" offset="6">
              <FormItem label="订单金额" prop="goods_pay">
                <strong>{{pay_sale.item.goods_pay}}</strong>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12" offset="6">
              <FormItem label="已收金额" prop="goods_pay">
                <span class="main-font-color">{{pay_sale.item.real_pay}}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12" offset="6">
              <FormItem label="收款金额" prop="current_pay">
                <Input v-model="pay_sale.item.current_pay" placeholder="请填写收款金额" type="number"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12" offset="6">
              <FormItem label="收款时间" prop="pay_time">
                  <DatePicker :value="pay_sale.item.pay_time" type="datetime"
                              format="yyyy-MM-dd HH:mm:ss"
                              placeholder="请填写收款时间(不填，默认当前时间)" style="width: 275px"
                              @on-change="getSalePayDateTime"></DatePicker>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12" offset="6">
              <FormItem label="说明" prop="pay_desc">
                <Input v-model="pay_sale.item.pay_desc" placeholder="请填写说明"></Input>
                <div v-for="item in common.pay_desc_list" @click="setPayDesc(pay_sale.item, item.name)" class="ivu-tag ivu-tag-size-default ivu-tag-default ivu-tag-checked pointer">
                  <span class="c-515a6e">{{ item.name }}</span>
                </div>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="closePaySale">关闭</Button>
        <Button type="primary" :loading="pay_sale.modal_loading" @click="paySaleOperate(pay_sale.item)">确认收款</Button>
      </div>
    </Modal>

    <!--便捷添加供应商-->
    <Modal v-model="add_client.modal" title="便捷添加供应商" width="900">
      <div >
        <Form  ref="add_client" :model="add_client.item" :label-width="100" :rules="add_client.ruleInline" label-colon>
          <Row :gutter="10">
            <Col span="12">
              <FormItem label="供应商名称" prop="client_name">
                <Input v-model="add_client.item.client_name"  placeholder="请填写供应商名称"></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="类型" prop="type">
                <Select v-model="add_client.item.type" placeholder="请选择类型">
                  <Option v-for="item in add_client.type_list" :value="item.id">{{ item.name }}</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="closeAddClient">关闭</Button>
        <Button type="primary" :loading="add_client.modal_loading" @click="addClientFast('add_client')">保存</Button>
      </div>
    </Modal>

    <SelectGoods ref="select_goods" @selected="getSelectedGoods" :select_goods="select_goods"/>

    <!--图片预览-->
    <ImagePreview ref="image_preview"/>
  </div>
</template>

<script>
import SelectGoods from '@/components/common/SelectGoods.vue';
import NP, {minus} from 'number-precision';
import ImageUpload from '@/components/common/ImageUpload.vue';
import ImagePreview from '@/components/common/Image/ImagePreview';
import ColumnsSet from "@/components/common/ColumnsSet";
import * as logisticsUtil from '@/utils/logistics';
import * as clientsUtil from '@/utils/client';
import * as address_util from '@/utils/address';

import {
  apiCreateSaleOrder,
  apiGetOrderList,
  apiPaySaleOperate,
  apiSetSalePayStatusEnd,
  apiSetInvoicingStatusDone,
  apiSaleForceDeleteOperate
} from "@/api/sale/sale";
import {apiGetPayDescAll} from "@/api/setting/payDesc";
import {apiAddClientFast} from "@/api/setting/clientSetting";
import * as supplier_utils from "@/utils/supplier";

export default {
  name: "SaleList",
  data() {
    return {
      special_rights_gross_profit: 0,
      add_client: {
        modal: false,
        modal_loading: false,
        item: {
          id:0,
          client_name:'',
          type: 1,
        },
        ruleInline: {
          client_name: [
            {required: true, message: '请填写客户名称'}
          ],
        },
        type_list: [
          {
            id:1,
            name: '客户',
          },
          {
            id:3,
            name: '客户与供应商',
          },
        ],
      },
      taxList: [
        {id:1,name:'是'},
        {id:2,name:'否'},
      ],
      invoicingStatusList: [
        {id:0,name:'暂无'},
        {id:1,name:'已开票'},
        {id:2,name:'待开票'},
      ],
      pick_way:[
        {
          "id": 1,
          "name": '客户自提'
        },
        {
          "id": 2,
          "name": '物流'
        },
        {
          "id": 3,
          "name": '送货'
        },
      ],
      money_return: {
        modal:false,
        modal_loading: false,
        item: {
          client_id:0,
          sale_id: 0,
          goods_pay: 0,
          real_pay: 0,
          return_pay: 0,
          remark:'',
        }
      },
      pay_sale: {
        modal:false,
        modal_loading: false,
        item: {
          id: 0,
          real_pay: 0,
          goods_pay: 0,
          current_pay: '',
          pay_time: '',
          pay_desc: '',
        }
      },
      sale_export: {
        modal: false,
        modal_loading: false,
        title: '销售单导出',
        item: {
          subject: '',
        },
      },
      departmentList: [],
      select_goods: {
        modal: false,
        count_flag: 0,
        goods_type: 0,//物料1 商品2 3半成品  4虚拟商品
        client_id: 0,
      },
      filter_bar: {
        sale_no: '',
        status: '',
        sale_date: '',
        client: '',
        project_id: 0,
        pay_status: '',
        include_tax: 0,
      },
      all_sale_status: [
        {
          "id": 1,
          "name": '待提交'
        },

        {
          "id": 4,
          "name": '已提交'
        },
      ],
      all_sale_pay_status: [
        {
          "id": 1,
          "name": '待收款'
        },
        {
          "id": 3,
          "name": '已收款'
        },
      ],
      clientFormItem: {
        id: '',
        sale_no: '',
        client_id: '',
        client_name: '',
        include_tax: 2,
        invoicing_status:'',
        address: '',
        goods_setted: [],
        flow_id: '',
        certificate: [],
        remark: '',
        sale_date: '',
        logistics_id: '',
        logistics_no: '',
        logistics_pay: 0,
        pick_way: 1,//自提
        discount_amount: 0,
        goods_pay: 0,
        to_pay: 0,
        count_price: 0,//根据商品计算出的总价
        count_num: 0,//根据商品计算出的总数量
        uploadParam: {
          list_length: 3,
          upload_type: 2,//2表示凭证上传
          module_type: 3,//3表示销售
          prefix: '',
          list: [],
          key: 0,
        },
        return_goods_set: [],
        return_cost: 0,
        sale_person: 0,
        department_id: 0,
        real_pay: 0,
        pay_status: '',
        pay_desc: '',
        flow_info: [],
        return_flow_info:[],
      },

      check_order: {
        title: '查看订单信息',
        modal: false,
      },

      //创建
      add_edit: {
        modal: false,
        model_title: '创建销售单信息',
      },

      modal_loading: {
        success: false,
        add_edit: false,
      },

      list_more: {
        column_key: 'sale_list_columns',
        visible: false,
        user_selected_column: [],
        all_columns: [
          {
            title: '销售单号',
            key: 'sale_no',
            slot: 'sale_no',
            align: 'center',
            minWidth: 40,
          },
          {
            title: '摘要',
            key: 'abstract',
            slot: 'abstract',
            minWidth: 120,
            align: 'left',
          },
          {
            title: '单号状态',
            key: 'status',
            slot: 'status',
            width: 100,
            align: 'center',
          },
          {
            title: '销售数量',
            key: 'goods_num',
            align: 'center',
          },
          {
            title: '销售金额',
            key: 'goods_pay',
            align: 'center',
          },
          {
            title: '实收金额',
            key: 'real_pay',
            align: 'center',
          },
          {
            title: '收款状态',
            key: 'pay_status',
            slot: 'pay_status',
            align: 'center',
          },
          {
            title: '客户',
            key: 'supplier_name',
            align: 'center',
          },
          {
            title: '销售时间',
            slot: 'sale_date',
            key: 'sale_date',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
            minWidth: 60,
            fixed: true,
          }
        ],
        base_selected_column: [
          '销售单号',
          '单号状态',
          '销售数量',
          '销售金额',
          '收款状态',
          '客户',
          '操作'
        ],
        columns:[],
      },
      //列表
      list: {
        data: [],
        total: 0,
        size: 0,
        page: 1,
        page_size_key: 'sale_page_size_key',
        page_size_opts: [10, 15, 20, 30],
      },
      ruleInline: {
        client_id: [
          {required: true, message: '请选择供应商'},
        ],
        real_pay: [
          {required: true, message: '请填写实收金额'},
        ],
      },
      file: null,
      logisticsList: [],
      clientList: [],
      table_columns_modal: [
        {
          title: '商品编号',
          key: 'part_no',
          align: 'center',
        },
        {
          title: '商品名称',
          key: 'part_name',
          slot: 'part_name',
          minWidth: 200,
          align: 'left',
        },
        // {
        //   title: '缩略图',
        //   key: 'thumbnail_url',
        //   slot: 'thumbnail_url',
        //   align: 'center',
        // },
        {
          title: '销售数量',
          key: 'sale_num',
          slot: 'sale_num',
          align: 'center',
        },
        {
          title: '销售单价',
          key: 'sale_price',
          slot: 'sale_price',
          align: 'center',
        },
        {
          title: '金额',
          key: 'sale_money',
          align: 'center',
        },
        {
          title: '可用库存',
          slot: 'stock_all',
          key: 'stock_all',
          align: 'center',
        },
        {
          title: '操作',
          key: 'action',
          width: 180,
          slot: 'action',
          align: 'center',
        }
      ],
      sale_return: {
        title: "补充销售退货信息",
        modal: false,
        item: {
          logistics_id: '',//物流ID
          return_reason: '',//退货理由
          sale_id: '',//销售单ID
          logistics_order_id: '',//物流单号,
          address_id: '',//收获地址ID
          goods_set: [],
          goods_pay: 0,
        },
        modal_loading: false,
      },
      address_list: [],
      common: {
        pay_desc_list:[],
        view_flag: 1,
        return_columns: [
          {
            title: '商品编号',
            key: 'part_no',
            align: 'center',
          },
          {
            title: '商品名称',
            key: 'part_name',
            align: 'center',
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center',
          },
          {
            title: '退货数量',
            key: 'sale_num',
            slot: 'sale_num',
            align: 'center',
          },
          {
            title: '退货单价',
            key: 'sale_price',
            slot: 'sale_price',
            align: 'center',
          },
          {
            title: '金额',
            key: 'sale_money',
            align: 'center',
          },
          {
            title: '可用库存',
            slot: 'stock_all',
            key: 'stock_all',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            slot: 'action'
          }
        ],
        list_loading: false,
        view_loading: false,
        // project_list: [],
        real_pay_columns: [
          {
            title: '流水单号',
            key: 'flow_id',
            align: 'center',
          },
          {
            title: '金额',
            key: 'money',
            slot: 'money',
            align: 'center',
          },
          {
            title: '收款时间',
            key: 'flow_time',
            align: 'center',
          },
          {
            title: '备注',
            key: 'desc',
            align: 'center',
          },
          {
            title: '操作人员',
            key: 'operator_name',
            align: 'center',
          },
        ]
      }
    };
  },
  watch: {
    'clientFormItem.count_price': {
      handler(newVal, oldVal) {
        this.setOrderPay()
      },
      deep: true,
    },
    'clientFormItem.logistics_pay': {
      handler(newVal, oldVal) {
        this.setOrderPay()
      },
      deep: true,
    },
  },
  methods: {
    forceDelete(row) {
      this.$Modal.confirm({
        title: "强制删除采购订单信息",
        content: '确定强制删除采购订单信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.forceDeleteOperate(row.id);
        },
        onCancel: () => {
        }
      });
    },
    forceDeleteOperate(id) {
      apiSaleForceDeleteOperate({id: id}).then(res => {
        if (res.data.err_no == 0) {
          this.$Message.success(res.data.results);
          this.getOrderList();
        } else {
          this.$Message.error(res.data.err_msg);
        }
      })
    },
    addClientFast(name) {
      this.add_client.modal_loading = true;
      this.$refs[name].validate((valid) => {
        if (valid) {
          apiAddClientFast(this.add_client.item).then(res => {
            if (res.data.err_no == 0) {
              this.$Message.success('操作成功！');
              this.add_client.modal = false;
              supplier_utils.resetSupplierAll();
              clientsUtil.resetClientsAll();
              this.add_client.modal_loading = false;

              this.add_client.item.client_id = res.data.results;
              this.clientList.push(this.add_client.item);
              this.clientFormItem.client_id = res.data.results;
            } else {
              this.add_client.modal_loading = false;
              this.$Message.error(res.data.err_msg);
            }
          }).catch( error => {
            this.add_client.modal_loading = false;
          })
        } else {
          this.add_client.modal_loading = false;
        }
      });
    },
    addClients() {
      this.add_client.client_name = '';
      this.add_client.type = 1;
      this.add_client.modal = true;
    },
    closeAddClient() {
      this.add_client.client_name = '';
      this.add_client.type = 1;
      this.add_client.modal = false;
    },
    setRealPay() {
      this.clientFormItem.real_pay = this.clientFormItem.goods_pay;
    },
    pickWayChange(e) {
      if (e != 2) {
        this.clientFormItem.logistics_id = 0;
        this.clientFormItem.logistics_no = '';
        this.clientFormItem.logistics_pay = 0;
      }
    },
    setPayDesc(item, name) {
      item.pay_desc = name;
    },
    getPayDescAll() {
      apiGetPayDescAll().then(res => {
        if (res.data.err_no == 0) {
          this.common.pay_desc_list = res.data.results;
        }
      });
    },
    setPayStatusFinish(item, show_type='view') {
      if (item.pay_status != 2 || [1,5].includes(item.status)) {
        return;
      }
      this.$Modal.confirm({
        title: "订单收款金额状态完结操作",
        content: '销售金额为: <strong class="status-main">'+item.goods_pay.toString()
            +'</strong><br/> 实收金额为: <strong class="status-main">'+item.real_pay.toString()+'</strong><br/>'
            + '确定将订单状态设置为<span class="status-danger">已收款</span>状态？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
            main_no: item.sale_no,
            link_id: item.id
          }
          apiSetSalePayStatusEnd(param).then(res => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              if (show_type === 'view') {
                this.checkOrderInfo(item);
              }
              this.getOrderList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },

    minus(a, b) {
      return NP.minus(a, b)
    },

    getSaleDateTime(value, type) {
      this.clientFormItem.sale_date = value;
    },
    getSalePayDateTime(value, type) {
      this.pay_sale.item.pay_time = value;
    },
    //查看图片
    imgShow(value, img_info) {
      this.$refs.image_preview.imgPreview(value, img_info);
    },
    getFileList(val) {
      this.clientFormItem.certificate = [];
      for (let i = 0; i < val.length; i++) {
        this.clientFormItem.certificate[i] = {};
        this.clientFormItem.certificate[i].url = val[i].url;
      }
    },
    getSelectedGoods(val) {
      //获取选中的商品
      let selected_goods = val;
      let setted_goods = this.clientFormItem.goods_setted;

      if (selected_goods.length) {
        for (let i = 0, len = selected_goods.length; i < len; i++) {
          let added_flag = false;

          //排除已添加的
          for (let j = 0, jlen = setted_goods.length; j < jlen; j++) {
            if (setted_goods[j].id == selected_goods[i].id) {
              added_flag = true;
              break;
            }
          }

          //如果未添加
          if (!added_flag) {
            selected_goods[i].sale_money = 0;
            selected_goods[i].sale_num = '';
            selected_goods[i].sale_price = '';
            selected_goods[i].free_flag = 0;//是否为赠品
            setted_goods.push(selected_goods[i]);
          }
        }
      }
    },
    getPayStatusColor(index) {
      let rs = '';
      switch (index) {
        case 2:
          rs = 'color:#ff9900';
          break;
        case 3:
        case 4:
          rs = 'color:#2775ff';
          break;
        case 1:
          rs = 'color:#ed4014';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getPayStatusText(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = '待收款';
          break;
        case 2:
          rs = '待收款';
          break;
        case 3:
          rs = '已收款';
          break;
        case 4:
          rs = '已收款';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getStatusColorTg(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = 'background-color:#ed4014';
          break;
        case 2:
          rs = 'background-color:#19be6b';
          break;
        case 3:
        case 8:
        case 7:
          rs = 'background-color:#2db7f5';
          break;
        case 4:
        case 10:
          rs = 'background-color:#2775ff';
          break;
        case 5:
          rs = 'background-color:#c5c8ce';
          break;
        case 6:
        case 9:
        case 11:
          rs = 'background-color:#ff9900';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getStatusColor(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = 'color:#ed4014';
          break;
        case 2:
          rs = 'color:#19be6b';
          break;
        case 3:
        case 8:
        case 7:
          rs = 'color:#2db7f5';
          break;
        case 4:
        case 10:
          rs = 'color:#2775ff';
          break;
        case 5:
          rs = 'color:#c5c8ce';
          break;
        case 6:
        case 9:
        case 11:
          rs = 'color:#ff9900';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getStatusText(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = '待提交';
          break;
        case 2:
          rs = '待出库';
          break;
        case 3:
          rs = '已出库';
          break;
        case 4:
          rs = '已提交';
          break;
        case 7:
          rs = '已发货';
          break;
        case 8:
          rs = '出库中';
          break;
        case 5:
          rs = '已取消';
          break;
        case 6:
          rs = '已退货';
          break;
        case 9:
          rs = '有退货';
          break;
        case 10:
          rs = '已完成';//（该状态表示订单有退货）
          break;
        case 11:
          rs = '有退款';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },

    getPayTypeColor(index) {
      //获取不同配色
      return index == 2 ? 'color:#ff9900' : '';
    },

    //获取客户地址
    getClientAddress($client_id) {
      this.clientFormItem.address = '';
      for (let i = 0; i < this.clientList.length; i++) {
        if ($client_id === this.clientList[i].client_id) {
          this.clientFormItem.address = this.clientList[i].address;
          break;
        }
      }
    },
    //求总价
    priceSum(row, index) {
      if (isNaN(row.sale_num) || isNaN(row.sale_price)) {
        this.clientFormItem.goods_setted[index].sale_money = 0;
      } else {
          //赋值
          this.clientFormItem.goods_setted[index].sale_num = row.sale_num;
          this.clientFormItem.goods_setted[index].sale_price = row.sale_price;
          let total = NP.times(row.sale_num, row.sale_price);
          this.clientFormItem.goods_setted[index].sale_money = total;
          row.sale_money = total;
      }
    },

    // 去除已选择的商品
    deleteGoodsSelected(row, index) {
      this.clientFormItem.goods_setted.splice(index, 1);
    },
    freeGoodsSelected(row, index) {
      row.free_flag = 1;
      row.sale_price = 0;
      this.clientFormItem.goods_setted[index].free_flag = 1;
      this.clientFormItem.goods_setted[index].sale_price = 0;

      this.priceSum(row, index)
    },
    chargeGoodsSelected(row, index) {
      row.free_flag = 0;
      this.clientFormItem.goods_setted[index].free_flag = 0;
    },

    selectTap(flag) {
      this.common.view_flag = flag;
    },

    //表格数据汇总
    addGoodsHandleSummary({columns, data}) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: '总计'
          };
          return;
        }
        const values = data.map(item => Number(item[key]));
        if (!values.every(value => isNaN(value)) && (index == 2 || index == 4)) {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return NP.plus(prev, curr);
            } else {
              return prev;
            }
          }, 0);

          if (index === 2) {
            sums[key] = {
              key,
              value: '共 ' + v
            };
            this.clientFormItem.count_num = v;
          } else if (index === 4) {
            sums[key] = {
              key,
              value: '共 ' + v
            };
            this.clientFormItem.count_price = v;
          }
        } else {
          sums[key] = {
            key,
            value: ''
          };
        }
      });

      return sums;
    },
    setOrderPay() {
      this.clientFormItem.goods_pay =  NP.minus(this.clientFormItem.count_price, this.clientFormItem.discount_amount, this.clientFormItem.logistics_pay);
    },

    //添加商品
    addGoods() {
      this.$refs.select_goods.select_goods.client_id = this.clientFormItem.client_id
      this.$refs.select_goods.select_goods.goods_type = this.select_goods.goods_type;
      this.$refs.select_goods.showGoodsList();
    },
    handleReset(name) {
      this.$refs[name].resetFields();
      //弹出框重置
      this.add_edit.modal = false;
      this.modal_loading.add_edit = false;
    },
    create(name) {
      this.$refs[name].resetFields();

      //创建商品信息
      this.add_edit.modal = true;
      this.modal_loading.add_edit = false;

      this.clientFormItem.id = '';
      this.clientFormItem.uploadParam.list = [];
      this.clientFormItem.uploadParam.key += 1;
      this.clientFormItem.logistics_id = '';
      this.clientFormItem.logistics_no = '';
      this.clientFormItem.logistics_pay = 0;

      this.$axios.get('/api/sale/getSaleId').then((response) => {
        if (response.data.err_no == 0) {
          this.clientFormItem.sale_no = response.data.results;
          this.clientFormItem.goods_setted = [];
          this.clientFormItem.uploadParam.prefix = this.clientFormItem.sale_no;
        }
      });
    },

    listEdit(row) {
      this.getSaleOrderById(row.id);

      this.add_edit.model_title = '修改销售订单信息';
      this.add_edit.modal = true;
      this.modal_loading.add_edit = false;
    },

    //获取销售单信息
    getSaleOrderById(id) {
      this.common.view_loading = true;
      this.$axios.get('/api/sale/getSaleOrderById?id=' + id).then((res) => {
        this.common.view_loading = false;
        let result = res.data.results;
        if (res.data.err_no == 0) {
          this.clientFormItem.id = result.id;
          this.clientFormItem.sale_no = result.sale_no;
          this.clientFormItem.status = result.status;
          this.clientFormItem.goods_num = parseFloat(result.goods_num);
          this.clientFormItem.goods_pay = parseFloat(result.goods_pay);
          this.clientFormItem.real_pay = parseFloat(result.real_pay);
          this.clientFormItem.gross_profit = parseFloat(result.gross_profit);
          this.clientFormItem.to_pay = NP.minus(this.clientFormItem.goods_pay, this.clientFormItem.real_pay)
          this.clientFormItem.pay_status = result.pay_status;
          this.clientFormItem.pay_desc = result.pay_desc;
          this.clientFormItem.client_id = result.client_id;
          this.clientFormItem.logistics_id = result.logistics_id == 0 ? '':result.logistics_id;
          this.clientFormItem.logistics_no = result.logistics_no;
          this.clientFormItem.include_tax = result.include_tax;
          this.clientFormItem.invoicing_status = result.invoicing_status;
          this.clientFormItem.logistics_pay = parseFloat(result.logistics_pay);
          this.clientFormItem.pick_way = result.pick_way;
          this.clientFormItem.address = result.address;
          this.clientFormItem.sale_date = result.sale_date;
          this.clientFormItem.certificate = result.certificate;
          this.clientFormItem.remark = result.remark;
          this.clientFormItem.discount_amount = parseFloat(result.discount_amount);

          for (let i=0; i<result.goods_setted.length; i++) {
            result.goods_setted[i].sale_num = parseFloat(result.goods_setted[i].sale_num);
            result.goods_setted[i].sale_price = parseFloat(result.goods_setted[i].sale_price);
            result.goods_setted[i].sale_money = parseFloat(result.goods_setted[i].sale_money);
          }
          this.clientFormItem.goods_setted = result.goods_setted;

          // this.clientFormItem.return_goods_set = [];
          // if (result.return_goods_set != undefined) {
          //   for (let i=0; i<result.return_goods_set.length; i++) {
          //     result.return_goods_set[i].sale_num = parseFloat(result.return_goods_set[i].sale_num);
          //     result.return_goods_set[i].sale_price = parseFloat(result.return_goods_set[i].sale_price);
          //     result.return_goods_set[i].sale_money = parseFloat(result.return_goods_set[i].sale_money);
          //   }
          //   this.clientFormItem.return_goods_set = result.return_goods_set;
          // }
          // this.clientFormItem.return_cost = result.return_cost != undefined ? parseFloat(result.return_cost) : 0;

          for (let i=0; i<result.flow_info.length; i++) {
            result.flow_info[i].money = parseFloat(result.flow_info[i].money);
          }
          this.clientFormItem.flow_info = result.flow_info;

          // for (let i=0; i<result.return_flow_info.length; i++) {
          //   result.return_flow_info[i].money = parseFloat(result.return_flow_info[i].money);
          // }
          // this.clientFormItem.return_flow_info = result.return_flow_info;

          this.clientFormItem.uploadParam.prefix = this.clientFormItem.sale_no;
          this.clientFormItem.uploadParam.list = JSON.parse(JSON.stringify(this.clientFormItem.certificate));
          this.clientFormItem.uploadParam.key += 1;
        }
      });
    },

    //删除操作提示
    listDeleteAlert(row) {
      this.$Modal.confirm({
        title: "删除销售订单信息",
        content: '确定删除销售订单信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteListInfo(row.id);
        },
        onCancel: () => {
        }
      });
    },

    //取消订单
    cancelSaleOrder(row) {
      this.$Modal.confirm({
        title: "取消销售订单信息",
        content: '确定取消销售订单信息？一旦取消订单，该订单将失效，不再有效。',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
            id: row.id,
            sale_no: row.sale_no,
          };
          this.$axios.post('/api/sale/cancelSaleOrder', param).then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.check_order.modal = false;
              this.getOrderList();
            } else {
              this.$Message.error(response.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    //删除订单
    deleteListInfo($id) {
      this.$axios.post('/api/sale/deleteListInfo', {id: $id}).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
          this.getOrderList();
          this.check_order.modal = false;
        } else {
          this.$Message.error(response.data.err_msg);
        }
      });
    },

    //创建销售订单
    createSaleOrder(name) {
      this.modal_loading.add_edit = true;

      this.$refs[name].validate((valid) => {
        if (valid) {
          //判断是否添加了销售商品
          let goods_setted = [];
          if (this.clientFormItem.goods_setted.length < 1) {
            this.$Message.error('请添加销售商品');
            this.modal_loading.add_edit = false;
            return;
          } else {
            //过滤无效数据
            for (let i = 0; i < this.clientFormItem.goods_setted.length; i++) {
              let tmp = {
                goods_id: '',
                sale_num: 0,
                sale_price: 0,
              };
              tmp.goods_id = this.clientFormItem.goods_setted[i].id;
              tmp.sale_num = this.clientFormItem.goods_setted[i].sale_num;
              tmp.sale_price = this.clientFormItem.goods_setted[i].sale_price;
              tmp.free_flag = this.clientFormItem.goods_setted[i].free_flag;

              if (tmp.sale_num <= 0) {
                this.$Message.error('销售商品数量不能小于等于0');
                this.modal_loading.add_edit = false;
                return;
              }

              if (tmp.sale_price <= 0 && tmp.free_flag != 1) {
                this.$Message.error('销售商品单价不能小于等于0');
                this.modal_loading.add_edit = false;
                return;
              }

              //不支持负库存
              if (tmp.sale_num > this.clientFormItem.goods_setted[i].stock_all) {
                  this.$Message.error('销售商品数量不能大于库存');
                  this.modal_loading.add_edit = false;
                  return;
              }

              goods_setted[i] = tmp;
            }
          }

          if (this.clientFormItem.discount_amount < 0) {
            this.$Message.error('优惠不能小于0！');
            this.modal_loading.add_edit = false;
            return;
          }

          if (this.clientFormItem.logistics_pay < 0) {
            this.$Message.error('物流运费不能小于0！');
            this.modal_loading.add_edit = false;
            return;
          }

          if (this.clientFormItem.real_pay<0) {
            this.$Message.error('实收金额不能小于0,实收金额也不能大于订单金额！');
            this.modal_loading.add_edit = false;
            return;
          }

          let client_name = '';
          for (let i=0; i<this.clientList.length; i++) {
            if (this.clientFormItem.client_id == this.clientList[i].client_id) {
              client_name = this.clientList[i].client_name;
            }
          }

          let param = new FormData(); //创建form对象
          param.append('id', this.clientFormItem.id);
          param.append('sale_no', this.clientFormItem.sale_no);
          param.append('client_id', this.clientFormItem.client_id);
          param.append('client_name', client_name);
          // param.append('project_id', this.clientFormItem.project_id != undefined ? this.clientFormItem.project_id: 0);
          param.append('real_pay', this.clientFormItem.real_pay);
          param.append('pay_desc', this.clientFormItem.pay_desc);
          param.append('address', this.clientFormItem.address);
          param.append('include_tax', this.clientFormItem.include_tax);
          param.append('invoicing_status', this.clientFormItem.invoicing_status);
          param.append('goods_setted', JSON.stringify(this.clientFormItem.goods_setted));
          param.append('certificate', JSON.stringify(this.clientFormItem.certificate));
          param.append('remark', this.clientFormItem.remark);
          param.append('discount_amount', this.clientFormItem.discount_amount);
          param.append('sale_date', this.clientFormItem.sale_date);
          param.append('pick_way', this.clientFormItem.pick_way);
          param.append('logistics_id', this.clientFormItem.logistics_id);
          param.append('logistics_no', this.clientFormItem.logistics_no);
          param.append('logistics_pay', this.clientFormItem.logistics_pay);
          // param.append('department_id', this.clientFormItem.department_id != undefined ? this.clientFormItem.department_id: 0);
          // param.append('sale_person', this.clientFormItem.sale_person != undefined ?  this.clientFormItem.sale_person : 0);

          apiCreateSaleOrder(param).then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getOrderList();
              this.add_edit.modal = false;
            } else {
              this.$Message.error(response.data.err_msg);
              this.modal_loading.add_edit = false;
            }
          }).catch((error) => {
            this.$Message.error('保存失败');
            this.modal_loading.add_edit = false;
          });
        } else {
          this.modal_loading.add_edit = false;
        }
      });
    },
    searchSubmit() {
      this.list.page = 1;
      this.getOrderList();
    },
    exportOperate() {
      this.sale_export.modal_loading = true;
      let param = {
        sale_no: this.filter_bar.sale_no,
        status: this.filter_bar.status,
        sale_date: this.filter_bar.sale_date,
        client: this.filter_bar.client,
        subject: this.sale_export.item.subject,
      };

      this.$axios.post('/api/sale/exportOrderList', param).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
        } else {
          this.$Message.error(response.data.err_msg);
          this.sale_export.modal_loading = false;
        }
        this.sale_export.modal = false;
      }).catch((error) => {
        this.$Message.error('导出失败');
        this.sale_export.modal_loading = false;
      });
    },
    exportSubmit(name) {
      this.$refs[name].resetFields();
      this.sale_export.modal_loading = false;
      this.sale_export.modal = true;
    },
    handleResetExportModal(name) {
      this.$refs[name].resetFields();
      this.sale_export.modal = false;
      this.sale_export.modal_loading = false;
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.list.page = 1;
      this.getOrderList();
    },

    //获取商品列表
    getOrderList() {
      this.common.list_loading = true;
      let param = {
        page: this.list.page,
        page_size: this.list.size,
        sale_no: this.filter_bar.sale_no,
        status: this.filter_bar.status,
        pay_status: this.filter_bar.pay_status,
        sale_date: this.filter_bar.sale_date,
        client: this.filter_bar.client,
        include_tax: this.filter_bar.include_tax,
      };
      apiGetOrderList(param).then((res) => {
        this.common.list_loading = false;
        if (res.data.err_no == 0) {
          for (let i=0; i<res.data.results.list.length; i++) {
            res.data.results.list[i].goods_num = parseFloat(res.data.results.list[i].goods_num);
            res.data.results.list[i].goods_pay = parseFloat(res.data.results.list[i].goods_pay);
            res.data.results.list[i].real_pay = parseFloat(res.data.results.list[i].real_pay);
          }
          this.list.data = res.data.results.list;
          this.list.total = res.data.results.total;
          this.list.size = parseInt(res.data.results.size);
        }
      });
    },
    changePage(page) {
      this.list.page = page;
      this.getOrderList();
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },
    pageSizeChange(page_size) {
      this.list.size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getOrderList();
    },
    getClientList() {
      clientsUtil.getClientsAll().then(res => {
        this.clientList = res;
      })
    },

    //打开审核页面
    checkOrderInfo(row) {
      //待审核状态时，显示标题为 审核订单信息
      if (row.status != 1) {
        //非审核状态时,标题为 查看订单信息
        this.check_order.title = '查看订单信息';
      }

      // this.getPersonList();
      this.getSaleOrderById(row.id);

      this.check_order.modal = true;
      this.modal_loading.success = false;
      this.common.view_flag = 1;
    },

    //显示订单信息
    showEditSaleOrder(clientFormItem) {
      this.listEdit(clientFormItem);
      this.check_order.modal = false;
    },

    //销售订单审核通过
    passSaleOrder(clientFormItem) {
      this.modal_loading.success = true;

      this.$axios.post('/api/sale/passSaleOrder', {id: clientFormItem.id}).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
          this.getOrderList();
          this.check_order.modal = false;
        } else {
          this.$Message.error(response.data.err_msg);
          this.modal_loading.success = false;
        }
      });
    },

    paySaleOperate(row) {
      this.pay_sale.modal_loading = true;
      if (row.current_pay ==0 || row.current_pay<0) {
        this.pay_sale.modal_loading = false;
        this.$Message.error('收款金额不能为0或负数！');
        return false;
      }

      this.$Modal.confirm({
        title: "收款确认提示",
        content: '收款操作即将执行，请确认填写无误？当收款金额加上已付金额大于等于订单金额时，订单将自动完结！',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
            link_id : row.id,
            pay_money: row.current_pay,
            pay_time: row.pay_time,
            remark: row.pay_desc,
          }
          apiPaySaleOperate(param).then(res => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.pay_sale.modal = false;
              this.getSaleOrderById(row.id);
              this.getOrderList();
              this.pay_sale.modal_loading = false;
            } else {
              this.pay_sale.modal_loading = false;
              this.$Message.error(res.data.err_msg);
            }
          }).catch( error => {
            this.pay_sale.modal_loading = false;
          })
        },
        onCancel: () => {
          this.pay_sale.modal_loading = false;
        }
      });

    },
    paySaleOrder(row) {
      this.pay_sale.item.id = row.id;
      this.pay_sale.item.goods_pay = row.goods_pay;
      this.pay_sale.item.real_pay = row.real_pay;
      this.pay_sale.item.current_pay = NP.minus(row.goods_pay, row.real_pay);
      this.pay_sale.item.remark = '';
      this.pay_sale.item.pay_time = '';
      this.pay_sale.modal = true;
    },
    closePaySale() {
      this.pay_sale.modal = false;
    },
    setInvoicingStatus(row) {
      this.modal_loading.success = true;
      let param = {
        id: row.id,
        main_no: row.sale_no
      }
      apiSetInvoicingStatusDone(param).then(res => {
        if (res.data.err_no == 0) {
          this.$Message.success(res.data.results);
          this.getOrderList();
          this.check_order.modal = false;
          //加载按钮不在这里关闭
        } else {
          this.$Message.error(res.data.err_msg);
          this.modal_loading.success = false;
        }
      }).catch((error) => {
        this.$Message.error('操作失败');
        this.modal_loading.success = false;
      });
    },

    //订单完成
    finishSaleOrder(clientFormItem) {
      this.modal_loading.success = true;

      this.$axios.post('/api/sale/finishSaleOrder', {id: clientFormItem.id}).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
          this.getOrderList();
          this.check_order.modal = false;
        } else {
          this.$Message.error(response.data.err_msg);
          this.modal_loading.success = false;
        }
      });
    },

    //审核弹出框重置
    handleResetOrderModal(item) {
      this.check_order.modal = false;
      item.modal = false;
    },

    timeChange(dateRange) {
      if (dateRange[0] && dateRange[1]) {
        this.filter_bar.sale_date = dateRange;
      } else {
        this.filter_bar.sale_date = "";
      }

      this.searchSubmit();
    },

    //退货时获取收货地址
    getAddressList() {
      address_util.getAddressAll().then(res => {
        this.address_list = res;
      });
    },

    getLogisticsAll() {
      logisticsUtil.getLogisticsAll().then(res => {
        this.logisticsList = res;
      });
    },
  },
  mounted() {
    this.$refs.columns_set.getUserColumns();

    this.setSelectedPageSize();
    this.getOrderList();

    this.getLogisticsAll();
    // this.getProjectList();
    this.getClientList();
    // this.getSaleDepartmentInfo();
    this.getPayDescAll();

    this.special_rights_gross_profit = localStorage.getItem('special_rights_gross_profit');

  },
  created() {
    this.aCheck.plateName = 'sale_manage';
  },
  components: {
    ColumnsSet,
    SelectGoods,
    ImageUpload,
    ImagePreview,
  }
};
</script>

<style scoped>
.select-flag {
  font-size: 16px;
  font-weight: 500;
}
#sale-list {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

.modalForm {
  width: 100%;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

.client_export {
  position: absolute;
  right: 110px;
  top: -8px;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#list {
  margin-top: 12px;
  margin-bottom: 40px;
  position: relative;
}

#list .page {
  margin-top: 16px;
}

#sale-list .ivu-card {
  background-color: #fafafa;
}

#sale-list .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#sale-list .ivu-form-item {
  margin-bottom: 0;
}

#sale-list .ivu-btn-small {
  font-size: 12px !important;
}

#list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#list .ivu-page {
  float: right;
}
</style>
<style>
#sale-list #filter-bar .ivu-card-body {
  padding: 6px 16px 16px 16px;
}
#sale-list .ivu-form-item {
  margin-bottom: 0;
  margin-top: 10px;
}

.ivu-divider-horizontal {
  margin: 10px 0 !important;
}
.mt5 {
  margin-top: 5px;
}

#list .ivu-spin-fix {
  top: 40px !important;
  height: calc(100% - 40px) !important;
}

.sale_list .ivu-table-overflowX, .ivu-table-tip {
  overflow-x: hidden;
}

.sale_list .ivu-table-wrapper {
  overflow: visible;
}

.ivu-tabs-nav .ivu-tabs-tab-active {
  font-weight: bold;
}

#filter-bar .ivu-tag-checked .ivu-tag-text {
  color: #515a6e !important;
}
</style>
