<template>
  <div id="sale-print-template" class="module-body">
    <div class="panel-header">
      <span class="top-title">销售单打印配置</span>
    </div>
    <div class="module-info">
      <div id="a5-print">
        <div class="header">
          <div>
            <img v-show="a5Item.logo" style="height:12mm;" :src="a5Item.logo" alt="">
          </div>
          <div class="fs-22 fw-400 align-center"><span class="bg-warn-color">{{ a5Item.company_name }}</span>销售单</div>
          <div>
            <img v-show="a5Item.wx_code" style="width:12mm;height:12mm;" :src="a5Item.wx_code" alt="">
          </div>
        </div>
        <div class="column-info">
          <div><strong>NO.XS00000908</strong></div>
          <div style="display:flex;justify-content: space-between;">
            <span>客户：进销存软件客户(18556786961)</span>
            <span>日期：2023-04-25</span>
          </div>
          <div><span>收货地址: 上海市虹口区庙张大厦19楼</span></div>
        </div>
        <div style="margin-top:5px;">
          <table cellspacing="0" cellpadding="0" border="1">
            <thead>
            <tr>
              <th><span>编号</span></th>
              <th><span>产品</span></th>
              <th><span>数量</span></th>
              <th><span>单价</span></th>
              <th><span>金额</span></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><span>1</span></td>
              <td><span>禾晓空调</span>
              </td>
              <td><span>2</span>
              </td>
              <td><span>2599</span>
              </td>
              <td><span>5198.00</span>
              </td>
            </tr>
            <tr>
              <td><span>2</span></td>
              <td><span>禾晓空调</span>
              </td>
              <td><span>2</span>
              </td>
              <td><span>2599</span>
              </td>
              <td><span>5198.00</span>
              </td>
            </tr>
            <tr>
              <td><span>3</span></td>
              <td><span>禾晓空调</span>
              </td>
              <td><span>2</span>
              </td>
              <td><span>2599</span>
              </td>
              <td><span>5198.00</span>
              </td>
            </tr>
            <tr>
              <td><span>4</span></td>
              <td><span>禾晓空调</span>
              </td>
              <td><span>2</span>
              </td>
              <td><span>2599</span>
              </td>
              <td><span>5198.00</span>
              </td>
            </tr>
            <tr>
              <td><span>5</span>
              </td>
              <td><span>禾晓空调</span>
              </td>
              <td><span>2</span>
              </td>
              <td><span>2599</span>
              </td>
              <td><span>5198.00</span>
              </td>
            </tr>
            <tr>
              <td><span>6</span>
              </td>
              <td><span>禾晓空调</span>
              </td>
              <td><span>2</span>
              </td>
              <td><span>2599</span>
              </td>
              <td><span>5198.00</span>
              </td>
            </tr>
            <tr>
              <td><span></span>
              </td>
              <td><span>合计</span>
              </td>
              <td><span>123</span>
              </td>
              <td><span></span></td>
              <td><span>8732.30</span></td>
            </tr>
            <tr>
              <td colspan="7" style="text-align: right;padding-left:10px;">
                <span >优惠: 20</span>
                <span class="ml-10">合计金额: <strong>捌仟柒佰叁拾贰元零叁角</strong>（<strong>8732.30</strong>）</span>
              </td>
            </tr>
            <tr>
              <td colspan="7" style="text-align: left;padding-left:10px;">
                <span>备注：加急订单</span>
              </td>
            </tr>
            <tr>
              <td colspan="7" style="text-align: left;padding-left:10px;">
                <span>*尊敬的客户：货物的数量、品种、规格，请当面点清*</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="column-info">
          <div style="display:flex;justify-content: space-between;">
            <span>订货电话：<span class="bg-warn-color">{{ a5Item.contact_phone }}</span></span>
            <span>制单人：刘销售</span>
          </div>
          <div style="display:flex;justify-content: space-between;">
            <span>订货地址: <span class="bg-warn-color">{{ a5Item.contact_address }}</span></span>
            <span>收货人(签字)：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </div>
          <div style="font-size:10px;" class="bg-warn-color">{{ a5Item.company_introduce }}</div>
        </div>
      </div>
      <div id="a5-input-info">
        <Form :model="a5Item" label-position="top" :label-colon="labelColon">
          <div class="align-center padding-4"><h2>内容设置</h2></div>
          <FormItem label="公司名称">
            <Input v-model="a5Item.company_name" placeholder="请配置公司名称"></Input>
          </FormItem>
          <FormItem label="订货电话">
            <Input v-model="a5Item.contact_phone" placeholder="请配置订货电话"></Input>
          </FormItem>
          <FormItem label="订货地址">
            <Input v-model="a5Item.contact_address" placeholder="请配置订货地址"></Input>
          </FormItem>
          <FormItem label="底部公司介绍">
            <Input v-model="a5Item.company_introduce" type="textarea" :autosize="{minRows: 2,maxRows: 5}" placeholder="Enter something..."></Input>
          </FormItem>
          <FormItem label="公司logo">
            <ImageUpload ref="logo_image_upload" @fileList="getLogo" :uploadParam="common.logoUploadParam" :key="common.logoUploadParam.key"/>
          </FormItem>
          <FormItem label="微信二维码">
            <ImageUpload ref="code_image_upload" @fileList="getWxCode" :uploadParam="common.wxCodeUploadParam" :key="common.wxCodeUploadParam.key"/>
          </FormItem>
          <FormItem v-if="aCheck.rightCheck('edit_sale_print')">
            <Button type="primary" @click="setOrderPrintTemplate">保存</Button>
            <Button style="margin-left: 8px" @click="resetTemplate">重置</Button>
          </FormItem>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import ImageUpload from '@/components/common/ImageUpload.vue';

import {setOrderPrintTemplate, getOrderPrintTemplate} from '@/api/setting/orderPrintSetting';


export default {
  name: "SalePrintTemplate",
  data() {
    return {
      before: {
        logo: 'https://xzlanshan.oss-cn-shanghai.aliyuncs.com/test/WechatIMG3353.png',
        wx_code: 'https://xzlanshan.oss-cn-shanghai.aliyuncs.com/test/WechatIMG3352.png',
        company_name: '禾晓商贸有限公司',
        contact_address: '广东省广州市番禺区兴吴路1055号',
        contact_phone: '18556786960',
        company_introduce: '本公司主营家用电器、日用百货、文体办公家具、劳保用品、五金管件、服饰等产品，如需购买扫描右上角微信二维码可直接添加好友',
        logo_array: [{url:'https://xzlanshan.oss-cn-shanghai.aliyuncs.com/test/WechatIMG3353.png'}],
        wx_code_array: [{url:'https://xzlanshan.oss-cn-shanghai.aliyuncs.com/test/WechatIMG3352.png'}],
      },
      reset: {
        logo: '',
        wx_code: '',
        company_name: '',
        contact_address: '',
        contact_phone: '',
        company_introduce: '',
        logo_array: [],
        wx_code_array: [],
      },

      labelColon: true,


      a5Item: {
        logo: '',
        logo_array: [],
        wx_code: '',
        wx_code_array: [],
        contact_address: '',

        company_name: '',
        contact_phone: '',
        company_introduce: '',

      },

      common: {
        logoUploadParam: {
          list_length: 1,
          upload_type: 3,//3表示模板上传
          module_type: 7,//7表示模板文件夹
          prefix: '',
          list: [],
          key: 0,
        },
        wxCodeUploadParam: {
          list_length: 1,
          upload_type: 3,//3表示模板上传
          module_type: 7,//7表示模板文件夹
          prefix: '',
          list: [],
          key: 0,
        },
      }
    }
  },
  mounted() {
    this.getOrderPrintTemplate();
  },
  methods: {
    getLogo(val) {
      this.a5Item.logo_array = [];
      this.a5Item.logo = '';
      for (let i = 0; i < val.length; i++) {
        this.a5Item.logo_array[i] = {};
        this.a5Item.logo_array[i].url = val[i].url;

        this.a5Item.logo = val[i].url;
      }
    },
    getWxCode(val) {
      this.a5Item.wx_code_array = [];
      this.a5Item.wx_code = '';
      for (let i = 0; i < val.length; i++) {
        this.a5Item.wx_code_array[i] = {};
        this.a5Item.wx_code_array[i].url = val[i].url;

        this.a5Item.wx_code = val[i].url;
      }
    },
    getOrderPrintTemplate() {
      getOrderPrintTemplate().then(res => {
        if (res.data.results.length > 0) {
          const content = JSON.parse(res.data.results[0].content);
          this.a5Item.logo = content.logo;
          this.a5Item.logo_array = content.logo_array;
          this.a5Item.wx_code = content.wx_code;
          this.a5Item.wx_code_array = content.wx_code_array;
          this.a5Item.contact_address = content.contact_address;
          this.a5Item.company_name = content.company_name;
          this.a5Item.contact_phone = content.contact_phone;
          this.a5Item.company_introduce = content.company_introduce;

          this.reset.logo = content.logo;
          this.reset.logo_array = content.logo_array;
          this.reset.wx_code = content.wx_code;
          this.reset.wx_code_array = content.wx_code_array;
          this.reset.contact_address = content.contact_address;
          this.reset.company_name = content.company_name;
          this.reset.contact_phone = content.contact_phone;
          this.reset.company_introduce = content.company_introduce;
        } else {
          this.a5Item.logo = this.before.logo;
          this.a5Item.wx_code = this.before.wx_code;
          this.a5Item.logo_array = this.before.logo_array;
          this.a5Item.wx_code_array = this.before.wx_code_array;
          this.a5Item.company_name = this.before.company_name;
          this.a5Item.contact_address = this.before.contact_address;
          this.a5Item.contact_phone = this.before.contact_phone;
          this.a5Item.company_introduce = this.before.company_introduce;

          this.reset.logo = this.before.logo;
          this.reset.logo_array = this.before.logo_array;
          this.reset.wx_code = this.before.wx_code;
          this.reset.wx_code_array = this.before.wx_code_array;
          this.reset.company_name = this.before.company_name;
          this.reset.contact_address = this.before.contact_address;
          this.reset.contact_phone = this.before.contact_phone;
          this.reset.company_introduce = this.before.company_introduce;
        }

        this.common.logoUploadParam.list = this.a5Item.logo_array;
        this.common.logoUploadParam.key += 1;

        this.common.wxCodeUploadParam.list = this.a5Item.wx_code_array;
        this.common.wxCodeUploadParam.key += 1;
      })
    },
    setOrderPrintTemplate() {
      let param = {
        content: JSON.stringify(this.a5Item)
      }
      setOrderPrintTemplate(param).then(res => {
        if (res.data.err_no === 0) {
          this.$Message.success(res.data.results);
          this.getOrderPrintTemplate();
        } else {
          this.$Message.error(res.data.results);
        }
      })
    },
    resetTemplate() {
      this.a5Item.logo = this.reset.logo;
      this.a5Item.logo_array = this.reset.logo_array;
      this.a5Item.wx_code = this.reset.wx_code;
      this.a5Item.wx_code_array = this.reset.wx_code_array;
      this.a5Item.contact_address = this.reset.contact_address;
      this.a5Item.company_name = this.reset.company_name;
      this.a5Item.contact_phone = this.reset.contact_phone;
      this.a5Item.company_introduce = this.reset.company_introduce;

      this.$Message.success('重置成功，还原到上次保留的信息状态');
    }
  },
  components: {
    ImageUpload,
  }
}
</script>

<style scoped>
/*#a5-print {*/
/*  width: 200mm;*/
/*  height: 138mm;*/
/*  background-color: #FFF;*/
/*  padding: 5mm;*/
/*}*/

#a5-print {
  width: 206mm;
  height: 146mm;
  background-color: #fff;
  padding: 3mm 2mm 2mm 2mm;
}

#a5-print .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 12mm;
}

#a5-print table {
  border-collapse: collapse; /* 合并边框 */
  width: 100%;
  border-color: #ccc;
  border-width: 0.75pt;
}


#a5-print table td, table th {
  height: 24px;
  text-align: center;
}

#a5-input-info {
  background-color: #fff;
  min-height: 138mm;
  margin-left: 10px;
  width: 90mm;
  padding: 10px;
}

.module-info {
  background-color: #f8f8f9;
  padding: 20px;
  margin-top: 20px;
  display: flex;
}

.bg-warn-color {
  background-color:#edf3ff;
}
</style>
