<template id="menu">
  <Menu theme="light" :active-name="active_name" @on-select="onselect" width="auto">
    <MenuGroup title="销售管理" v-if="aCheck.moduleCheck('sale_module')">
      <MenuItem name="1" v-if="aCheck.pageCheck('sale_list')">
        <Icon type="ios-infinite"/>
        销售列表
      </MenuItem>
      <MenuItem name="2" v-if="aCheck.pageCheck('sale_exchange')">
        <Icon type="logo-dropbox"/>
        商品置换单
      </MenuItem>
    </MenuGroup>
    <MenuGroup title="销售报表" v-if="aCheck.moduleCheck('sale_report_module')">
      <MenuItem name="4" v-if="aCheck.pageCheck('sale_goods_count')">
        <Icon type="ios-podium" />
        商品销量
      </MenuItem>
      <MenuItem name="5" v-if="aCheck.pageCheck('sale_money_count')">
        <Icon type="md-people" />
        客户售额
      </MenuItem>
    </MenuGroup>
    <MenuGroup title="配置管理" v-if="aCheck.moduleCheck('sale_price_module')">
<!--      <MenuItem name="3" v-if="aCheck.pageCheck('sale_price_manage')">-->
<!--        <Icon type="ios-settings"/>-->
<!--        售价配置-->
<!--      </MenuItem>-->
      <MenuItem name="6" v-if="aCheck.pageCheck('sale_print_manage')">
        <Icon type="ios-print"/>
        销售打印配置
      </MenuItem>
    </MenuGroup>
    <MenuGroup title="合同管理" v-if="aCheck.moduleCheck('sale_contract_module')">
      <MenuItem name="7" v-if="aCheck.pageCheck('sale_contract_manage')">
        <Icon type="ios-paper" />
        销售合同
      </MenuItem>
    </MenuGroup>
  </Menu>
</template>

<script>
import * as loginUtil from '@/utils/login'

export default {
  name: "SaleManageMenu",
  data() {
    return {
      active_name: this.active,
      menu_name: this.menuName,
    };
  },
  methods: {
    onselect(name) {
      this.$emit('func', name);
      localStorage.setItem(this.menu_name, name);
      loginUtil.setExpireTime();
    },
    setNavInfo(name) {
      this.active_name = name.toString()
    }
  },
  created() {
    this.aCheck.plateName = 'sale_manage';
  },
  props: ["active", "menuName"],
};
</script>

<style scoped>
#menu {
  width: 200px;
  position: absolute;
  left: 0;
}
</style>
