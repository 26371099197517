<template>
  <div id="sale-num" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">客户售额</span>
    </div>

    <div id="filter-bar" class="filter-bar">
      <Card dis-hover>
        <Form ref="list_search_item" :model="list.search_item" inline>
          <FormItem prop="client_id">
            <Select @on-change="getMainList()" v-model="list.search_item.client_id" placeholder="请选择销售客户" filterable class="w-200">
              <Option v-for="item in common.client_list" :value="item.client_id" :key="item.client_id">{{ item.client_name }}</Option>
            </Select>
          </FormItem>

          <FormItem>&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch('list_search_item')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <div id="list" class="main-list">
      <Table :columns="list.columns" :data="list.data">

        <template slot-scope="{ row }" slot="client_name">
          <strong v-for="item in common.client_list" v-if="row.client_id==item.client_id">{{ item.client_name }}</strong>
        </template>

<!--        <template slot-scope="{ row }" slot="contact_person">-->
<!--          <span v-for="item in common.client_list" v-if="row.client_id==item.client_id">{{ item.contact_person }}</span>-->
<!--        </template>-->


        <template slot-scope="{ row }" slot="updated_at">
          <span>{{ row.updated_at.substring(0, 16) }}</span>
        </template>
      </Table>

      <Row class="mt-16">
        <Col span="6">
          <span class="fs12 disabled-color"><Icon type="ios-alert-outline"/> 销量数据每天凌晨定时更新一次</span>
        </Col>
        <Col span="18" class="page">
          <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
                :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>

        </Col>
      </Row>
    </div>

  </div>
</template>

<script>
import {
  getSaleClientAmountList,
} from '@/api/sale/clientSaleAmount';

import * as clientsUtil from '@/utils/client'

export default {
  name: "ClientSaleNum",
  data() {
    return {
      list: {
        page_size_key: 'sale_client_num_page_size_key',
        page_size_opts: [10, 15, 20, 30],
        size:0,
        page:1,
        search_item: {
          client_id: '',
        },
        columns: [
          {
            title: '客户名称',
            key: 'client_name',
            slot: 'client_name',
            align: 'center',
          },
          // {
          //   title: '联系人',
          //   key: 'contact_person',
          //   slot: 'contact_person',
          //   align: 'center',
          // },
          {
            title: '总销售额',
            key: 'amount',
            align: 'center',
          },
          {
            title: '当月销售额',
            key: 'month_amount',
            align: 'center',
          },
          {
            title: '更新时间',
            key: 'updated_at',
            slot: 'updated_at',
            align: 'center',
          },
        ],
        data: []
      },
      common: {
        client_list: []
      }
    }
  },
  methods: {
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.getMainList();
    },
    getMainList() {
      let param = {
        page: this.list.page,
        page_size: this.list.size,
        client_id: this.list.search_item.client_id,
      };

      getSaleClientAmountList(param).then((res) => {
        this.list.data = res.data.results.list;
        this.list.total = res.data.results.total;
        this.list.size = res.data.results.size;
      });
    },
    changePage(page) {
      this.list.page = page;
      this.getMainList();
    },
    pageSizeChange(page_size) {
      this.list.size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getMainList();
    },
    getClientList() {
      clientsUtil.getClientsAll().then(res => {
        this.common.client_list = res;
      });
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },
  },
  mounted() {
    this.setSelectedPageSize();
    this.getMainList();

    this.getClientList();
  }
}
</script>

<style scoped>
#sale-num .ivu-card {
  background-color: #fafafa;
}

#sale-num .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}
</style>
<style>
#sale-num .ivu-form-item {
  margin-bottom: 0;
}
</style>
